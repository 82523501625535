import React from "react";

function StayUpdated() {
  return (
    <div class="container-fluid bg-primary text-secondary p-5">
      <div class="row g-5">
        <div class="col-12 text-center">
          <h1 class="display-5 mb-4">Stay Update!!!</h1>
          <form class="mx-auto" style={{ maxWidth: "600px" }}>
            <div class="input-group">
              <input
                type="text"
                class="form-control border-white p-3"
                placeholder="Your Email"
              />
              <button class="btn btn-dark px-4">Sign Up</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StayUpdated;
