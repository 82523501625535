import React from "react";
import { Box } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./CommonSliderStyle.css";

import { t } from "i18next";

function CommonSlider(props) {
  console.log("props : ", props);
  const settings = {
    pauseOnHover: false,
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <Box className={props.className}>
      <Slider {...settings}>
        {props.imgs.map((img, i) => (
          <div className="common-slide" key={i}>
            <img className="common-slide-image" alt="slider" src={img} />{" "}
            {/* <div className="common-slide-det-container">
              <h1 className="common-slide-header">{t("vision_phrase1")}</h1>
              <h1 className="common-slide-subtitle">{t("vision_phrase2")}</h1>
            </div> */}
          </div>
        ))}
      </Slider>
    </Box>
  );
}

export default CommonSlider;
