import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { verifyEmail } from "../../backendServices/authService";

const VerifyEmail = () => {
  const { search } = useLocation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(search);
    const token = params.get("token");

    // console.log("query token : ", token);
    if (token) {
      //   const response = verifyEmail(token);
      //   console.log("verify Email.response: ", response.response);

      //   setMessage(response?.data);

      axios
        .get(
          `${process.env.REACT_APP_NODE_API}/auth/verify-email?token=${token}`
        )
        .then((response) => {
          console.log("response : ", response);
          setMessage(response.data);
        })
        .catch((error) => {
          setMessage(error.response?.data || "Error occurred");

          console.log("response error : ", error);
        });
    }
  }, [search]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        width: "100%",
        height: "100px",
        backgroundColor: "var(--dark)",
        color: "var(--light)",
      }}
    >
      <p>{message}</p>
    </div>
  );
};

export default VerifyEmail;
