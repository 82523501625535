import axios from "axios";

const API_URL = `${process.env.REACT_APP_NODE_API}/auth`;

export const register = (formData) => {
  return axios.post(`${API_URL}/register`, { formData });
};

export const loginApi = (email, password) => {
  console.log("API_URL : ", API_URL);
  return axios.post(`${API_URL}/login`, { email, password });
};

export const verifyToken = (token) => {
  return axios.post(`${API_URL}/verify-token`, { token });
};

export const verifyEmail = (token) => {
  return axios.get(`${API_URL}/verify-email?token=${token}`);
};
