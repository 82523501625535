import React, { useContext, useState } from "react";
import axios from "axios";
import { register } from "../../../backendServices/authService";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import "./RegisterStyle.css";
import Notify from "../../Notify";
import { t } from "i18next";
import { Context } from "../../LocalWrapper";

import { regEx } from "../../../util/regEx";

const Register = () => {
  const context = useContext(Context);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    natId: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    natId: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");
    if (!hasErrors) {
      try {
        const response = await register(formData);
        console.log("reg.response: ", response.data);
        setMessage(response.data.message);
        // navigate("/login");
        Notify(1, response.data.message);
      } catch (error) {
        Notify(0, error?.response?.data?.message);
        console.log("Registration failed, error", error);
        // setMessage(error?.response?.data?.message || "Error occurred");
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      Notify(0, `Make sure that all data is correct.`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    const newErrors = {};
    const error = validateField(name, value);
    if (error) {
      newErrors[name] = error;
    } else {
      newErrors[name] = "";
    }
    setErrors({ ...errors, ...newErrors });
  };

  const validateField = (name, value) => {
    switch (name) {
      case "fullName":
        return regEx.name.test(value)
          ? ""
          : "Username must be 3-16 characters and only letters.";
      case "email":
        return regEx.mailTest.test(value) ? "" : "Invalid email address.";
      case "natId":
        return regEx.natIdNoTest.test(value) ? "" : "Invalid national id.";
      case "phone":
        return regEx.mobileNumberTest.test(value) ? "" : "Invalid phone.";

      default:
        return "";
    }
  };

  return (
    <Box dir={context.dir} className="r_section-container">
      <Grid container spacing={2} sx={{ pt: "70px" }}>
        <Grid item xs={1} sm={2} md={3} lg={4}></Grid>
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <div className="register-det-container container p-4 shadow-sm bg-white rounded">
            <h3 className="mb-4 text-center">{t("register_header")}</h3>
            {message ? (
              <p>{message}</p>
            ) : (
              <form onSubmit={handleRegister} className="register-form">
                <div className="form-group mb-3">
                  <label htmlFor="username" className="form-label">
                    {t("full_name")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.fullName ? "input-error" : ""
                    }`}
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                  />
                  {errors.fullName ? (
                    <span
                      style={{
                        color: "#e74c3c",
                      }}
                    >
                      {errors.fullName}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("email")}
                  </label>
                  <input
                    type="email"
                    className={`form-control ${errors.email && "input-error"}`}
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {errors.email ? (
                    <span style={{ color: "#e74c3c" }}>{errors.email}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="natId" className="form-label">
                    {t("nat_id")}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${errors.natId && "input-error"}`}
                    id="natId"
                    name="natId"
                    value={formData.natId}
                    onChange={handleChange}
                  />
                  {errors.natId ? (
                    <span style={{ color: "#e74c3c" }}>{errors.natId}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="phone" className="form-label">
                    {t("phone")}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${errors.phone && "input-error"}`}
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone ? (
                    <span style={{ color: "#e74c3c" }}>{errors.phone}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="password" className="form-label">
                    {t("password")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary w-100"
                >
                  {loading ? "Registering..." : t("register")}
                </button>
                {/* Show a loading spinner or message */}
                {/* {loading && <p>Sending verification email... Please wait.</p>} */}
                {loading && (
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                )}
              </form>
            )}
          </div>
        </Grid>
        <Grid item xs={1} sm={2} md={3} lg={4}></Grid>
      </Grid>
    </Box>
  );
};

export default Register;
