import { Routes, Route } from "react-router-dom";

import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import About from "./components/about/About";
import GoToTop from "./components/GoToTop";
import InstCenters from "./components/centers/InstCenters";
import TrackingServicesDetails from "./components/services/TrackingServicesDetails";
import Documents from "./components/documents/Documents";

import { ToastContainer } from "react-toastify";

import GigaBot from "./components/GigaBot/GigaBot";
import CybersecurityServicesDetails from "./components/services/CybersecurityServicesDetails";
import SoftwareDevServicesDetails from "./components/services/SoftwareDevServicesDetails";
import NetworkServicesDetails from "./components/services/NetworkServicesDetails";
import CloudServicesDetails from "./components/services/CloudServicesDetails";
import SystemIntegrationServicesDetails from "./components/services/SystemIntegrationServicesDetails";
import CookieConsent from "react-cookie-consent";
import { t } from "i18next";
import Reservation from "./components/reservation/Reservation";
import { useEffect, useState } from "react";
import RequireAuth, {
  verifyTokenWithBackend,
} from "./backendServices/RequireAuth";
import Login from "./components/userBookingLogic/login/Login";
import Register from "./components/userBookingLogic/register/Register";
import Dashboard from "./components/userBookingLogic/Dashboard";
import AppointmentForm from "./components/userBookingLogic/book/AppointmentForm";
import Applications from "./components/apps/Applications";
import Cookies from "js-cookie";
import VerifyEmail from "./components/userBookingLogic/VerifyEmail";
// import BusinessCard from "./components/about/BusinessCard/BusinessCard";
const authed = async (token) => await verifyTokenWithBackend(token);

function App() {
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(Cookies.get("authToken"));
    // const isAuthed = authed(token);
    // console.log("check authed : ", isAuthed);
  }, [token]);

  // useEffect(() => {
  // }, [token]);

  return (
    <div className="App">
      <Navbar />
      <ToastContainer />

      <CookieConsent
        dir="ltr"
        location="bottom"
        enableDeclineButton
        flipButtons
        buttonText={t("cookies_acceptBtn")}
        declineButtonText={t("cookies_declinedBtn")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", width: "400px", margin: "30px" }}
        buttonStyle={{ color: "#4e503b" }}
        expires={1}
      >
        {t("cookies_pragraph")}
        <br />
        <a
          href="https://www.iubenda.com/privacy-policy/95821041"
          className="privacy-policy-link"
          target="_blank"
          rel="noreferrer"
        >
          <span>{t("cookies_policy")}</span>
        </a>
      </CookieConsent>

      <GigaBot />
      <Routes>
        {/* ********** booking and user registering section ************** */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Dashboard token={token} />} />
          <Route path="/book" element={<AppointmentForm token={token} />} />
        </Route>
        {/* ********** ************************************ ************** */}
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/applications" element={<Applications />} />
        {/* <Route path="/bcd/:id" element={<BusinessCard />} /> */}
        <Route path="/centers" element={<InstCenters />} />
        {/* ******************* services details ******************* */}
        <Route
          path="/tracking-services-det"
          element={<TrackingServicesDetails />}
        />
        <Route
          path="/cyber-security-services-det"
          element={<CybersecurityServicesDetails />}
        />
        <Route
          path="/software-development-services-det"
          element={<SoftwareDevServicesDetails />}
        />
        <Route
          path="/network-services-det"
          element={<NetworkServicesDetails />}
        />
        <Route
          path="/system-integration-services-det"
          element={<SystemIntegrationServicesDetails />}
        />
        <Route path="/cloud-services-det" element={<CloudServicesDetails />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/reservation" element={<Reservation />} />
      </Routes>
      <GoToTop />
      <Footer />
    </div>
  );
}

export default App;
