import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import { Context } from "../../LocalWrapper";
import { useTranslation } from "react-i18next";

import infoVideo from "../../../imgs/about/infoVideo.mp4";
import infoArVideo from "../../../imgs/about/infoVideoAR.mp4";
import posterImg from "../../../imgs/about/etit_comp_img.jpg";

function InfoVideo() {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "10vh",
        // p: 3,
        // pt: 8,
      }}
    >
      <Box sx={{ flexGrow: 1, color: "white" }}>
        {/****************** Cyber management system */}

        <Grid sx={{ p: "20px 5px" }} container>
          <Grid item xs={1} sm={1} md={2} lg={2}></Grid>
          <Grid item xs={10} sm={10} md={8} lg={8}>
            <Box
            //   dir={context.dir}
            >
              <video
                controls
                preload="auto"
                // autoPlay
                poster={posterImg}
                className="info-video"
                style={{ width: "100%" }}
              >
                {context.dir === "ltr" ? (
                  <source src={infoVideo} type="video/mp4" />
                ) : (
                  <source src={infoArVideo} type="video/mp4" />
                )}
              </video>
            </Box>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2}></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default InfoVideo;
