import { Box, Grid } from "@mui/material";
import React from "react";
import "./PrivacyPolicyStyle.css";

import { Context } from "../../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
function PrivacyPolicy() {
  const context = useContext(Context);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        padding: "0% 2%",
        minHeight: "5vh",

        backgroundColor: "var(--section-background)",
        marginBottom: "6%",
        // position: "relative",
        // backgroundColor: "red",
      }}
      dir={context.dir}
    >
      {/* privacy policy section */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "3% 0px",
            }}
          >
            <h1 className="pp-subtitle">{t("about_privacy_policy_header")}</h1>
            <p className="pp-paragraph">
              {t("about_privacy_policy_p")}
              <a
                href="https://www.iubenda.com/privacy-policy/95821041"
                className="privacy-policy-link"
                target="_blank"
                rel="noreferrer"
              >
                <span>{t("about_privacy_policy_header")}</span>
              </a>
            </p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PrivacyPolicy;
