import { Box, Grid } from "@mui/material";
import React from "react";
import "./FooterStyle.css";
import etitLogo from "../../imgs/etit_logo.png";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Context } from "../LocalWrapper";
import { HashLink } from "react-router-hash-link";

function Footer() {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        // flexGrow: 1,
        width: "100%",
        // minHeight: "40vh",
        // mt: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        bgcolor: "var(--dark)",
        paddingTop: "20px",
        marginTop: "auto",
      }}
      dir={context.dir}
    >
      <Box sx={{ flexGrow: 1, color: "white" }}>
        <Grid
          sx={
            ({ p: "20px 5px" },
            context.dir === "rtl"
              ? { paddingInlineStart: "100px" }
              : { paddingInlineStart: "5px" })
          }
          container
        >
          <Grid item xs={12} sm={3} md={3} lg={3} className="footer-links">
            <h1>{t("nav_products")}</h1>
            <a href="/tracking-services-det">{t("tracking_services")} </a>
            <a href="/cyber-security-services-det">
              {t("cyber_security_services")}
            </a>
            <a href="/software-development-services-det">
              {t("development_services")}
            </a>
            <a href="/network-services-det">{t("network_services")} </a>
            <a href="/cloud-services-det">{t("cloud_services")} </a>
            <a href="/system-integration-services-det">
              {t("system_integration_services")}
            </a>
            {/* <a href="/tracking-services-det">{t("footer_servicess")} </a> */}
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} className="footer-links">
            <h1>{t("nav_company_clients")}</h1>
            <a href="/about">{t("footer_about")}</a>
            <HashLink
              to="/#contact"
              // key={item.name}
              // smooth
            >
              {t("nav_contact")}
            </HashLink>
            {/* <a href="/tracking-services-det">{t("footer_servicess")} </a> */}
            <a href="/documents">{t("footer_docs")} </a>
            <a href="/centers">{t("footer_centers")} </a>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} className="footer-links">
            <h1>{t("cont_det_email")}: </h1>
            <a href=" mailto:info@etit-eg.com">info@etit-eg.com</a>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={3}
            md={3}
            lg={3}
            className="footer-img"
          >
            <Box>
              <img src={etitLogo} alt="etit logo" />
              <h5>
                <a href="tel:19382" style={{ color: "#FF8C05" }}>
                  {t("footer_hotline")}
                </a>
              </h5>
            </Box>
          </Grid>
        </Grid>
        <Box className="footer-bottom">
          <p>ETIT© 2023. All Rights Reserved.</p>
          <p>Privacy Policy</p>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
