import React, { useContext } from "react";
import { Box } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./PartnersStyle.css";

import p1 from "../../imgs/partners/Auto-Group.png"; //landing slide
import p2 from "../../imgs/partners/EFG.png"; // etit building
import p3 from "../../imgs/partners/Egypt-foods.png"; // etit building
import p4 from "../../imgs/partners/Petrobel.png"; // etit building
import p5 from "../../imgs/partners/Toshiba.png"; // etit building
import p6 from "../../imgs/partners/customs.png"; // etit building
import p7 from "../../imgs/partners/ibnsina.png"; // etit building
import p8 from "../../imgs/partners/la-poire.png"; // etit building
import p9 from "../../imgs/partners/moe.png"; // etit building
import p10 from "../../imgs/partners/moh.png"; // etit building
import p11 from "../../imgs/partners/nile_taxi.png"; // etit building
import p12 from "../../imgs/partners/ntra.png"; // etit building
import p13 from "../../imgs/partners/passport.png"; // etit building
import p14 from "../../imgs/partners/qarun.png"; // etit building
import p15 from "../../imgs/partners/salab.png"; // etit building
import { t } from "i18next";
import { Context } from "../LocalWrapper";

function Partners() {
  const context = useContext(Context);
  // console.log("context: ", context.dir);
  const settings = {
    // pauseOnHover: true,
    // dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    speed: 2000,
    swipeToSlide: true,
    rtl: context.dir === "rtl" ? true : false,
    // initialSlide: 0,
    cssEase: "linear",
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <Box className="partners-section">
      <h1 className="partners-title">{t("partners-title")}</h1>

      <Slider className="partners-slider" {...settings} id={2}>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p1} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p2} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p3} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p4} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p5} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p6} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p7} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p8} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p9} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p10} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p11} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p12} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p13} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p14} />
        </div>
        <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p15} />
        </div>
        {/* <div className="partner-slide">
          <img className="partner-image" alt="partners" src={p2} />{" "}
          <div className="partner-slide-det-container">
            <h1 className="partner-slide-header">
              <span>ETIT</span> - Headquarter
            </h1>
          </div>
        </div> */}
      </Slider>
    </Box>
  );
}

export default Partners;
