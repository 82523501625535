import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import GoogleMap from "./GoogleMap";
import { Box, Button, FormGroup, Grid, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import { Context } from "../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./ContactUs.css";

import etit_location from "../../imgs/contact/etit_location.png";
import Notify from "../Notify";

const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "var(--primary)",
  },
  "& label": {
    color: "var(--primary)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "var(--primary)",
  },
  "& .MuiOutlinedInput-root": {
    color: "var(--paragraph)",
    "& fieldset": {
      borderColor: "var(--paragraph)",
    },
    "&:hover fieldset": {
      borderColor: "var(--primary)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary)",
    },
  },
});

function ContactUs() {
  const context = useContext(Context);
  const { t } = useTranslation();
  const form = useRef();
  let initState = {
    from_name: "",
    from_email: "",
    message: "",
  };
  const [contactDet, setContactDet] = useState(initState);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_u1pnggt",
        "template_hm6kpru",
        form.current,
        "8_7W74eooZLfP0Pf_"
      )
      .then(
        (result) => {
          console.log(result.text);
          Notify(1, "Thanks For Your Time.");
          clearForm();
        },
        (error) => {
          console.log(error.text);
          Notify(2, "Sorry, there is a problem!.");
        }
      );
  };
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setContactDet({ ...contactDet, [name]: value });
    // alert(JSON.stringify(contactDet));
  };

  const clearForm = () => {
    setContactDet(initState);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        padding: "5% 3%",
        backgroundColor: "#F3F3F3",
      }}
      dir={context.dir}
    >
      <Grid container className="contact-container" id="contact">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <form ref={form} onSubmit={sendEmail}>
            <FormGroup className="contact-det-container">
              <h1>{t("cont_header")}</h1>
              <CustomTextField
                name="from_name"
                label={t("cont_name")}
                onChange={onChange}
                value={contactDet.from_name}
                required
                sx={{ width: "70%", margin: "10px" }}
              />
              <CustomTextField
                name="from_email"
                label={t("cont_email")}
                onChange={onChange}
                value={contactDet.from_email}
                required
                type="email"
                sx={{ width: "70%", margin: "10px" }}
              />
              {/* <CustomTextField
              label={t("cont_subj")}
              required
              sx={{ width: "70%", margin: "10px" }}
            /> */}
              <CustomTextField
                name="message"
                label={t("cont_message")}
                onChange={onChange}
                value={contactDet.message}
                multiline
                rows={5}
                required
                sx={{ width: "70%", margin: "10px" }}
              />
              <a
                style={{
                  display: "block",
                  width: "40%",
                  height: "50px",
                  marginBottom: "20px",
                }}
                href={`mailto:info@etit-eg.com? subject=${contactDet.from_name}&body= ${contactDet.message}`}
              >
                <Button
                  variant="contained"
                  fontSize="large"
                  endIcon={<SendIcon sx={{ fontSize: "50px" }} />}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#d35400",
                    },
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                  dir="ltr"
                  // type="submit"
                  // onClick={() => sendEmail}
                >
                  {t("cont_send")}
                </Button>
                {/* I love star wars */}
              </a>

              {/* <input type="submit" value="Send" /> */}
            </FormGroup>
          </form>

          {/* <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="from_name" />
            <label>Email</label>
            <input type="email" name="from_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
          </form> */}
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              height: "55%",
              display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            }}
          >
            <Box className="contact-map-container">
              {/* <img
                src={etit_location}
                alt="img"
                style={{ width: "100%", height: "100%" }}
              /> */}
              <GoogleMap />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              minHeight: "45%",
            }}
          >
            <Box className="contacts">
              <h1>
                <span>{t("cont_det_call_center")}:</span> {"  "}
                <a href="tel:19382" style={{ color: "#22b0f2" }}>
                  19382
                </a>
              </h1>
              <h1>
                <span>{t("cont_det_email")}: </span>{" "}
                <a href=" mailto:info@etit-eg.com" style={{ color: "#22b0f2" }}>
                  info@etit-eg.com
                </a>
              </h1>
              <h1>
                <span>{t("cont_det_fax")}: </span>+202 35371015
              </h1>
              <h1>
                <span>{t("cont_det_address")}: </span>
                {t("cont_det_address_content")}
              </h1>
              <h1>
                <span>{t("cont_det_location")}: </span>
                <a
                  href="https://goo.gl/maps/pLQLePRJZq4eVWvg9"
                  target="_blank"
                  style={{ color: "#22b0f2" }}
                  rel="noreferrer"
                >
                  ETIT LOC
                  <PersonPinCircleIcon
                    fontSize="large"
                    sx={{ verticalAlign: "middle" }}
                  />
                  TION
                </a>
              </h1>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactUs;
