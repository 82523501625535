import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { palette } from "../../theme/colors";
import CustomerCreateForm from "./CustomerCreateForm2";

const steps = [
  "Customer Info",
  //   "Upload Documents",
  "Book an appointment",
  "Appointment confermation",
];

export default function ReservationStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "50px" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step
              key={label}
              sx={{
                ".MuiStepConnector-root": {
                  top: 0,
                  color: "#fff",
                },
                ".MuiStepConnector-root span": {
                  borderColor: "transparent",
                  color: "#fff",
                },
                ".MuiStepConnector-root span::before": {
                  display: "flex",
                  justifyContent: "center",
                  content: '">"',
                  color: "#fff",
                },
                ".MuiSvgIcon-root": {
                  borderRadius: "50%",
                  border: "1px solid #ff8c05",
                },
                ".MuiSvgIcon-root:not(.Mui-completed)": {
                  color: "white",
                  border: "1px solid #fff",
                },
                ".MuiStepIcon-text": {
                  fill: "#000",
                  fontWeight: 500,
                },
                ".MuiSvgIcon-root.Mui-completed": {
                  color: "#ff8c05",
                },
                ".MuiStepLabel-label": {
                  color: "#fff", // Just text label (COMPLETED)
                },
                ".MuiStepLabel-label.Mui-completed": {
                  color: "#ff8c05", // Just text label (COMPLETED)
                },
                ".MuiStepLabel-label.Mui-active": {
                  color: "#ff8c05", // Just text label (COMPLETED)
                },
                ".MuiSvgIcon-root.Mui-active": {
                  color: "#ff8c05",
                  padding: "3px",
                  borderRadius: "50%",
                  border: "1px solid #ff8c05",
                  marginY: "-3px",
                },
                ".Mui-active .MuiStepIcon-text": {
                  fill: "white",
                  fontSize: "20px",
                },
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ color: "white", mt: 2, mb: 1 }}>
            Reservation Done
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button sx={{ mr: 1, color: "white" }} onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ color: "white", mt: 2, mb: 1 }}>
            Step5555555555555555555555 {activeStep + 1}
          </Typography> */}
          <CustomerCreateForm />
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, backgroundColor: "#ff8c05", color: "white" }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
            <Button
              sx={{ mr: 1, backgroundColor: "#ff8c05", color: "white" }}
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
