import { Box, Grid } from "@mui/material";
import React from "react";
import "./CompDirectorsStyle.css";
import directorHeadBgImg from "../../../imgs/about/company_directors.png";

import {
  HighManagment,
  TechManagment,
  FinancialManagment,
} from "./MembersData";
import { Context } from "../../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function CompDirectors() {
  const context = useContext(Context);
  const { t } = useTranslation();
  return (
    <Box sx={{ p: "0px 10px" }} dir={context.dir}>
      <Box className="director-head-container">
        <img src={directorHeadBgImg} alt="ETIT" />
        <h1>{t("about_members_header")}</h1>
      </Box>
      {/* board members */}
      <Grid container spacing={5}>
        {HighManagment.map((item) => (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="director-item">
              <div>
                <img src={item.img} alt="ETIT" />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#00000000",
                  }}
                >
                  <h1 className="director-job">
                    {context.currentLang.code === "EN"
                      ? item.lat_title
                      : item.title}
                  </h1>
                  <h1 className="director-name">
                    {context.currentLang.code === "EN"
                      ? item.lat_name
                      : item.name}
                  </h1>
                </Box>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* directors */}
      <Grid
        container
        columnSpacing={15}
        rowSpacing={5}
        sx={{ marginTop: "50px" }}
      >
        <Grid
          item
          container
          spacing={2}
          xs={12}
          sm={6}
          md={6}
          lg={6}
          // sx={{ borderRight: "5px solid white", bgcolor:'blue' }}
        >
          {TechManagment.map((item) => (
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box className="director-item-sub">
                <div>
                  <img src={item.img} alt="ETIT" />
                  <h1 className="director-job">
                    {context.currentLang.code === "EN"
                      ? item.lat_title
                      : item.title}
                  </h1>
                  <h1 className="director-name">
                    {context.currentLang.code === "EN"
                      ? item.lat_name
                      : item.name}
                  </h1>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid
          item
          container
          spacing={2}
          xs={12}
          sm={6}
          md={6}
          lg={6}
          // sx={{ borderLeft: "5px solid white" , bgcolor:'green'}}
        >
          {FinancialManagment.map((item) => (
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box className="director-item-sub">
                <div>
                  <img src={item.img} alt="ETIT" />
                  <h1 className="director-job">
                    {context.currentLang.code === "EN"
                      ? item.lat_title
                      : item.title}
                  </h1>
                  <h1 className="director-name">
                    {context.currentLang.code === "EN"
                      ? item.lat_name
                      : item.name}{" "}
                  </h1>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default CompDirectors;
