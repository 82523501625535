export const handleScrollFun = (elementRef, setIsVisible, mobile) => {
  const handleScroll = (ref) => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      const isVisible = mobile
        ? rect.bottom >= 0 && rect.right >= 0
        : rect.bottom <=
            (window.innerHeight + 400 ||
              document.documentElement.clientHeight + 400) &&
          rect.right <=
            (window.innerWidth + 400 ||
              document.documentElement.clientWidth + 400);
      setIsVisible(isVisible);
    }
  };

  window.addEventListener("scroll", handleScroll);
  // Initial check on component mount
  handleScroll();

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
};
