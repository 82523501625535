import React, { useContext, useEffect, useState } from "react";
// import { login } from "../../../backendServices/authService";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import "./LoginStyle.css";
import { Context } from "../../LocalWrapper";

import { t } from "i18next";
import Notify from "../../Notify";
import { regEx } from "../../../util/regEx";

const Login = () => {
  const context = useContext(Context);

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (!hasErrors) {
      const result = await context.login(formData.email, formData.password);
      if (result === "ok") navigate("/");
    } else {
      Notify(0, `Make sure that all data is correct.`);
    }
    // window.location.reload();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    const newErrors = {};
    const error = validateField(name, value);
    if (error) {
      newErrors[name] = error;
    } else {
      newErrors[name] = "";
    }
    setErrors({ ...errors, ...newErrors });
  };

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        return regEx.mailTest.test(value) ? "" : "Invalid email address.";
      case "password":
        return regEx.pwTest.test(value) ? "" : "Invalid password.";

      default:
        return "";
    }
  };

  console.log("errors.email : ", errors);
  return (
    <Box dir={context.dir} className="section-container">
      <Grid container spacing={2}>
        <Grid item xs={1} sm={2} md={4} lg={1}></Grid>
        <Grid item xs={10} sm={8} md={4} lg={3}>
          <div className="login-det-container mt-5">
            <h4>ETIT</h4>
            <hr />
            <p>{t("login_header")}</p>
            <form onSubmit={handleLogin} className="login-form">
              <div className="form-group">
                <label>{t("email")}</label>
                <input
                  type="email"
                  name="email"
                  className={`form-control ${
                    errors.email ? "input-error" : ""
                  }`}
                  onChange={handleChange}
                  required
                />
                {errors.email ? (
                  <span
                    style={{
                      color: "#e74c3c",
                    }}
                  >
                    {errors.email}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label>{t("password")}</label>
                <input
                  type="password"
                  name="password"
                  className={`form-control ${
                    errors.password ? "input-error" : ""
                  }`}
                  onChange={handleChange}
                  required
                />
                {errors.password ? (
                  <span
                    style={{
                      color: "#e74c3c",
                    }}
                  >
                    {errors.password}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <button type="submit" className="login-btn btn btn-primary mt-3">
                {t("login")}
              </button>
            </form>

            <hr />
            <p>{t("no_account")}</p>
            <button
              type="submit"
              onClick={() => navigate("/register")}
              className="register-btn btn btn-primary mt-3"
            >
              {t("register")}
            </button>
          </div>
        </Grid>
        <Grid item xs={1} sm={2} md={4} lg={8}></Grid>
      </Grid>
    </Box>
  );
};

export default Login;
