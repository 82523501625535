import { Box, Grid } from "@mui/material";
import React from "react";
import directorHeadBgImg from "../../../imgs/about/company_directors.png";
import empImg1 from "../../../imgs/about/emp/emp1.png";
import empImg2 from "../../../imgs/about/emp/emp2.png";
import "./BestEmpStyle.css";

import { Context } from "../../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { BestEmps } from "./BestEmpData";

function BestEmp() {
  const context = useContext(Context);
  const { t } = useTranslation();
  return (
    <Box sx={{ p: "0px 10px" }} dir={context.dir}>
      <Box className="director-head-container">
        <img src={directorHeadBgImg} />
        <h1>{t("about_ideal_emps_header")}</h1>
      </Box>

      <Grid container spacing={5}>
        {BestEmps.map((item) => (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="emp-item">
              <div>
                <img src={item.img} />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#00000000",
                  }}
                >
                  <h1 className="emp-job">
                    {context.currentLang.code === "EN"
                      ? item.lat_title
                      : item.title}
                  </h1>
                  <h1 className="emp-name">
                    {context.currentLang.code === "EN"
                      ? item.lat_name
                      : item.name}
                  </h1>
                </Box>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default BestEmp;
