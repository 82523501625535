import axios from "axios";

const API_URL = `${process.env.REACT_APP_NODE_API}/appointments`;

export const getAppointments = (token) => {
  console.log("getAppointments services: ", token);
  return axios.get(API_URL, { headers: { Authorization: `Bearer ${token}` } });
};

export const createAppointment = (token, appointment) => {
  console.log("appointment : ", { ...appointment });
  return axios.post(
    API_URL,
    { ...appointment },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateAppointment = (token, appointment) => {
  return axios.put(API_URL, appointment, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteAppointment = (token, appointmentId) => {
  return axios.delete(API_URL, {
    headers: { Authorization: `Bearer ${token}` },
    data: { appointmentId },
  });
};
