import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
// import etitImg from "../../../imgs/about/company1.png";
// import privacy from "../../../imgs/about/privacy.png";
import "./CompProfileStyle.css";

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Context } from "../../LocalWrapper";

function CompProfile() {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
      }}
      // dir={context.dir}
    >
      {/* who we are section */}
      <Grid container className="who-container">
        <Grid item xs={12} sm={4} md={4} lg={5} className="who-img-container">
          {/* <img src={etitImg} /> */}
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "10% 0px",
              backgroundColor: "#2c3e505a",
            }}
          >
            <div
              dir={context.dir}
              style={{ flex: 1, display: "flex", flexDirection: "row" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h1 className="comp-subtitle1">
                  {t("about_profile_header1")}{" "}
                </h1>

                <h1 className="comp-subtitle2">{t("about_profile_header2")}</h1>
                <h1 className="comp-subtitle3">{t("about_profile_header3")}</h1>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <h1 className="comp-q-mark">{t("about_profile_q_mark")}</h1>
              </div>
            </div>
            <Box
              sx={{
                backgroundColor: {
                  xs: "#00000094",
                  sm: "#00000000",
                  md: "#00000000",
                  lg: "#00000000",
                },
              }}
              className="paragraph-container"
            >
              <p className="comp-paragraph">
                <span>{t("nav_compTitle")}</span>
                {t("about_profile_p1")}
              </p>
              <p className="comp-paragraph">{t("about_profile_p2")}</p>
              <p className="comp-paragraph">{t("about_profile_p3")}</p>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CompProfile;
