import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 500;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Wrapper>
      {isVisible && (
        <div className="top-btn" onClick={goToBtn}>
          <ArrowUpwardIcon
            sx={{
              "&:hover": {
                color: "#d35400",
              },
            }}
            className="top-btn--icon"
          />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .top-btn {
    font-size: 2.4rem;
    width: 2rem;
    height: 2rem;
    color: #fff;
    background-color: #ff8c05;
    box-shadow: 0px 2px 7px #a1a1a1;
    border-radius: 50%;
    position: fixed;
    bottom: 1rem;
    right: 50%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    

  @media (max-width: 1400) {
    .top-btn {
      right: 0;
      left: 40%;
    }
  }
`;

export default GoToTop;
