import { Box, Grid } from "@mui/material";
import React from "react";

import "./ServicesDetailsStyle.css";
import cloudImg from "../../imgs/services/cloud.png";

import OfflinePinIcon from "@mui/icons-material/OfflinePin";

import { Context } from "../LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function CloudServicesDetails() {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        p: 3,
        pt: 8,
      }}
    >
      {/* <h1 className="service-header">{t("development_services")}</h1> */}

      <Grid sx={{ p: "20px 5px" }} container>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          className="serv-img-det-container"
          sx={{ display: { xs: "flex", sm: "none", md: "none", lg: "none" } }}
        >
          <img src={cloudImg} alt="cloud" className="fleet-serv-det-img" />
        </Grid>

        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "10% 0px",
            }}
          >
            {context.dir === "ltr" ? (
              <h1 className="serv-subtitle" id="vehicle">
                <span>{t("serv_cloud_t_bold")}</span>
                {t("serv_cloud_t_normal")}
              </h1>
            ) : (
              <h1 className="serv-subtitle" id="vehicle">
                {t("serv_cloud_t_normal")}
                <span>{t("serv_cloud_t_bold")}</span>
              </h1>
            )}

            <p className="serv-paragraph">{t("serv_cloud_p")}</p>

            <ul className="serv-list-item" dir={context.dir}>
              <li>
                <OfflinePinIcon className="serv-list-item-icon" />{" "}
                {t("serv_det_cloud_li_1")}
              </li>
              <li>
                <OfflinePinIcon className="serv-list-item-icon" />{" "}
                {t("serv_det_cloud_li_2")}
              </li>
              <li>
                <OfflinePinIcon className="serv-list-item-icon" />{" "}
                {t("serv_det_cloud_li_3")}
              </li>
              <li>
                <OfflinePinIcon className="serv-list-item-icon" />{" "}
                {t("serv_det_cloud_li_4")}
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          className="serv-img-det-container"
          sx={{ display: { xs: "none", sm: "flex", md: "flex", lg: "flex" } }}
        >
          <img src={cloudImg} alt="cloud" className="serv-det-img" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CloudServicesDetails;
