import { Box, Typography } from "@mui/material";
import React from "react";
import "./DevicesSpecsStyle.css";

import ZoomInMapIcon from "@mui/icons-material/ZoomInMap"; // Compact Design
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood"; // Mini Size
import BatteryCharging90Icon from "@mui/icons-material/BatteryCharging90"; // Internal Battery
import HandymanIcon from "@mui/icons-material/Handyman"; // Easy Installation
import EventRepeatIcon from "@mui/icons-material/EventRepeat"; // Scheduled Timing Report
import FenceIcon from "@mui/icons-material/Fence"; // Geo-fences

import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent"; // Multiple I/O Interfaces
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled"; // Driving Behavior Monitoring
import SpeedIcon from "@mui/icons-material/Speed"; // Speed Alarm

import SensorsIcon from "@mui/icons-material/Sensors"; // Motion Detection
import WaterIcon from "@mui/icons-material/Water"; // Water Resistant
import SosIcon from "@mui/icons-material/Sos"; // Emergency Button
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote"; // OTA Control
import PetsIcon from "@mui/icons-material/Pets"; // Use with Animal Collars
import MessageIcon from "@mui/icons-material/Message"; // Up to 10,000 Buffer Messages
import Battery1BarIcon from "@mui/icons-material/Battery1Bar"; // Low Power Alarm

import CarCrashIcon from "@mui/icons-material/CarCrash"; // Crash Detection

import { Context } from "../../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function DevicesSpecs({ deviceName }) {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box className="specs-container">
      {deviceName !== "GL300" ? ( // common specs for non gl300
        <>
          <Typography className="specs-item">
            <SettingsInputComponentIcon className="specs-icon" />
            {t("specs_multiple_interfaces")}
          </Typography>

          <Typography className="specs-item">
            <DirectionsCarFilledIcon className="specs-icon" />
            {t("specs_driving_behavior_monitoring")}
          </Typography>

          <Typography className="specs-item">
            <SpeedIcon className="specs-icon" /> {t("specs_speed_alarm")}
          </Typography>

          <Typography className="specs-item">
            <CarCrashIcon className="specs-icon" /> {t("specs_crash_detection")}
          </Typography>
        </>
      ) : (
        //specs for gl300
        <>
          <Typography className="specs-item">
            <SensorsIcon className="specs-icon" /> {t("specs_motion_detection")}
          </Typography>

          <Typography className="specs-item">
            <WaterIcon className="specs-icon" /> {t("specs_water_resistant")}
          </Typography>

          <Typography className="specs-item">
            <SosIcon className="specs-icon" /> {t("specs_emergency_button")}
          </Typography>

          <Typography className="specs-item">
            <PetsIcon className="specs-icon" /> {t("specs_animal_collars")}
          </Typography>

          <Typography className="specs-item">
            <MessageIcon className="specs-icon" /> {t("specs_buffer_messages")}
          </Typography>

          <Typography className="specs-item">
            <Battery1BarIcon className="specs-icon" /> {t("specs_power_alarm")}
          </Typography>
        </>
      )}
      {/* common specs */}
      <Typography className="specs-item">
        <ZoomInMapIcon className="specs-icon" /> {t("specs_compact_design")}
      </Typography>

      <Typography className="specs-item">
        <SecurityUpdateGoodIcon className="specs-icon" /> {t("specs_mini_size")}
      </Typography>

      <Typography className="specs-item">
        <BatteryCharging90Icon className="specs-icon" />
        {t("specs_internal_battery")}
      </Typography>

      <Typography className="specs-item">
        <HandymanIcon className="specs-icon" /> {t("specs_easy_installation")}
      </Typography>

      <Typography className="specs-item">
        <EventRepeatIcon className="specs-icon" />
        {t("specs_scheduled_timing_report")}
      </Typography>

      <Typography className="specs-item">
        <FenceIcon className="specs-icon" /> {t("specs_geo_fences")}
      </Typography>

      <Typography className="specs-item">
        <SettingsRemoteIcon className="specs-icon" /> {t("specs_ota_control")}
      </Typography>
    </Box>
  );
}

export default DevicesSpecs;

// {deviceName != "GL300" && (
//     )}
