import { Box, Button, Grid } from "@mui/material";
import React, { useContext } from "react";
import "./OurDevicesStyle.css";
import gv55 from "../../imgs/devices/gv55.png";
import gv65 from "../../imgs/devices/gv65-plus.png";
import gv300 from "../../imgs/devices/gv300.png";
import gl300 from "../../imgs/devices/gl300.png";
import DevicesDetails from "./devicesDetails/DevicesDetails";

import { useTranslation } from "react-i18next";
import UserData from "./UserData";
import { Context } from "../LocalWrapper";

const devImages = {
  gv55: gv55,
  gv65: gv65,
  gv300: gv300,
  gl300: gl300,
};

function OurDevices() {
  const context = useContext(Context);
  const { t } = useTranslation();

  const devicesDataTrns = t("devicesData", { returnObjects: true });

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        p: 3,
      }}
      id="products"
    >
      <h1 className="devices-header">{t("sol_header")}</h1>

      <Grid sx={{ p: "20px 5px" }} container spacing={2}>
        {Object.entries(devicesDataTrns).map(([key, device]) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
            <div className="device-container">
              <img src={devImages[key]} alt="img1" />
              <h1>{device.name}</h1>
              <div class="overlay">
                <div class="text">
                  {device.det1}

                  {!context?.user?.email ? (
                    <UserData
                      t={t}
                      deviceData={{
                        name: device.name,
                        cat: device.cat,
                        det1: device.det1,
                        det2: device.det2,
                        det3: device.det3,
                      }}
                    />
                  ) : (
                    <DevicesDetails
                      t={t}
                      deviceData={{
                        name: device.name,
                        cat: device.cat,
                        det1: device.det1,
                        det2: device.det2,
                        det3: device.det3,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
        ))}

        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className="device-container">
            <img src={d2} />
            <h1>{t("devicesData.gv65.name")} </h1>
            <div class="overlay">
              <div class="text">
                {t("devicesData.gv65.det1")}
                <UserData
                  t={t}
                  deviceData={{
                    name: t("devicesData.gv65.name"),
                    cat: t("devicesData.gv65.cat"),
                    det1: t("devicesData.gv65.det1"),
                    det2: t("devicesData.gv65.det2"),
                    det3: t("devicesData.gv65.det3"),
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className="device-container">
            <img src={d3} />
            <h1>GV300</h1>
            <div class="overlay">
              <div class="text">
                {t("devicesData.gv300.det1")}
                <UserData
                  t={t}
                  deviceData={{
                    name: t("devicesData.gv300.name"),
                    cat: t("devicesData.gv300.cat"),
                    det1: t("devicesData.gv300.det1"),
                    det2: t("devicesData.gv300.det2"),
                    det3: t("devicesData.gv300.det3"),
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className="device-container">
            <img src={d4} alt="dev" />
            <h1>GL300</h1>
            <div class="overlay">
              <div class="text">
                {t("devicesData.gl300.det1")}
                <UserData
                  t={t}
                  deviceData={{
                    name: t("devicesData.gl300.name"),
                    cat: t("devicesData.gl300.cat"),
                    det1: t("devicesData.gl300.det1"),
                    det2: t("devicesData.gl300.det2"),
                    det3: t("devicesData.gl300.det3"),
                  }}
                />
              </div>
            </div>
          </div>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default OurDevices;
