export const regEx = {
  arabicLettersTest: /[\u0600-\u06FF\u0750-\u077F]/,
  name: /^([\u0600-\u06FF\u0750-\u077F\s]+){8}$|^([a-zA-Z\s]+){8}$/,
  dateTest:
    /(((^0[1-9]|[1][0-9]|[2][0-9]|3[0-1])-([0][1]|0[3-9]|1[0-2]))|((0[1-9]|[1][0-9]|[2][0-9])-([0][2])))-((1[9]|2[0])\d\d$)/,
  // /(^0[1-9]|[1][0-9]|[2][0-9]|3[0-1])-(0[1-9]|1[0-2])-((1[9]|2[0])\d\d$)/,
  natIdNoTest:
    /^([2][4-9][0-9]|[3][0-9][0-9])(([0][2]([0][1-9]|(1|2)[0-9]))|([0](4|6|9)([0][1-9]|(1|2)[0-9]|[3][0])|[1][1]([0][1-9]|(1|2)[0-9]|[3][0]))|([0](1|3|5|7|8)([0][1-9]|(1|2)[0-9]|[3](0|1)))|([1](0|2)([0][1-9]|(1|2)[0-9]|[3](0|1))))(01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d$/,
  passportTest: /^(?!^0+$)[a-zA-Z0-9]{3,20}$/,
  mobileNumberTest: /^\d{11}$/,
  phoneTest: /^[\d-+]{6,}$/,
  mailTest: /^\w+([\.-]?\w+)@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  taxCardNoTest: /(^\d{3}-\d{3}-\d{3}$)/,
  commRecTest: /^\d{3,}$/,
  pwTest: /^\w{3,}$/,
};

export const noAccentOrigin = {
  ک: "ك",
  // ﻷ: "لا",
  // ؤ: "و",
  // ى: "ی",
  // ي: "ی",
  // ئ: "ی",
  أ: "ا",
  إ: "ا",
  آ: "ا",
  ٱ: "ا",
  ٳ: "ا",
  ة: "ه",
  // ء: "",
  "ِ": "",
  "ْ": "",
  "ُ": "",
  "َ": "",
  "ّ": "",
  "ٍ": "",
  "ً": "",
  "ٌ": "",
  "ٓ": "",
  "ٰ": "",
  "ٔ": "",
  "�": "",
};
