import { Box, Divider, List, ListItem, ListItemButton } from "@mui/material";
import { Link } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";

export default function SideDrawer({
  handleDrawerToggle,
  navItems,
  etitLogo,
  context,
  changeLang,
}) {
  return (
    <Box
      onClick={() => handleDrawerToggle}
      sx={{ textAlign: "center", p: "10px" }}
    >
      <Link href="/">
        <img src={etitLogo} alt="ETIT LOGO" style={{ width: "50px" }} />
      </Link>
      <Divider />
      <List>
        {navItems.map((item) =>
          !item.children ? (
            <ListItem key={item.name} divider disablePadding>
              <ListItemButton
                sx={{ justifyContent: "flex-start" }}
                className="drawer-link"
                onClick={() => {
                  handleDrawerToggle();
                }}
              >
                <a
                  href={item.path}
                  className={"et-nav-link"}
                  // target={item.id === "login" ? "_blank" : ""}
                  rel="noreferrer"
                >
                  {item.name}
                </a>
              </ListItemButton>
            </ListItem>
          ) : (
            <div
              key={item.name}
              style={{
                width: "100%",
              }}
              className="dropdown btn-group drawer-services-dropdown"
            >
              <button
                className="btn dropdown-toggle drawer-link"
                style={{
                  color: "#fff",
                  padding: "0px",
                  border: "0px",
                  // width: "100%",
                  marginInlineStart: "25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
                // type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="drawer-link">{item.name}</span>
              </button>
              <ul className="dropdown-menu">
                {item.children.map(({ id, name, path }) => (
                  <li key={id}>
                    <a
                      className={
                        item.id === "login"
                          ? "nav-login"
                          : "services-dropdown-menu-link"
                      }
                      // target={item.id === "login" ? "_blank" : ""}
                      href={path}
                      key={id}
                      // smooth
                      rel="noreferrer"
                    >
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
      </List>
      <Divider />
      <div
        className="d-flex justify-content-center et-nav-link"
        style={{ padding: "0px" }}
      >
        <div className="dropdown">
          <button
            className="btn dropdown-toggle"
            style={{ color: "#fff", padding: "0px" }}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <LanguageIcon sx={{ width: "30px", height: "30px" }} />{" "}
            {context.currentLang.code}
          </button>
          <ul className="dropdown-menu">
            {context.languages.map(({ code, name, country_code }) => (
              <li key={code}>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    changeLang(code);
                    // setlangCode(code);
                    // window.location.reload(true);
                  }}
                >
                  <span className={`fi fi-${country_code} mx-2`}></span>
                  {name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Box>
  );
}
