import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { Context } from "../LocalWrapper";

import etitLogo from "../../imgs/etit_logo.png";
import quicklinkLogo from "../../imgs/devices/quicklinkLogo.png";
import egyFlag from "../../imgs/Egypt-Flag2.png";

import stolenCarImg from "../../imgs/devices/stolen-car.png";
import fleetManageImg from "../../imgs/devices/fleet-manag.png";

import assetTrackingImg from "../../imgs/devices/Asset-Tracking.png";
import personalSecurityImg from "../../imgs/devices/Personal-Security.png";

import { Box, Grid, TextField } from "@mui/material";

import DevicesSpecs from "./devicesDetails/DevicesSpecs";
import Login from "../userBookingLogic/login/Login";

export default function UserData({ t, deviceData }) {
  const context = useContext(Context);
  const [open, setOpen] = React.useState(false);

  // const name = deviceData.name;
  // const det1 = deviceData.det1;
  // const det2 = deviceData.det2;
  // const det3 = deviceData.det3;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          bgcolor: "#FF8C05",
          fontSize: "15px",
          fontWeight: "600",
          padding: "10px",
          margin: "15px",
          "&:hover": {
            backgroundColor: "#d35400",
          },
        }}
        onClick={handleClickOpen}
        dir={context.dir}
      >
        {t("device_specs")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { backgroundColor: "#fff", width: { xs: "90%", md: "90%" } },
        }}
        scroll="paper"
        maxWidth="lg"
        dir={context.dir}
      >
        <DialogTitle
          sx={{
            color: "white",
            backgroundColor: "#1f1f1f",
          }}
          id="alert-dialog-title"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Please Login
          </Box>
        </DialogTitle>

        <DialogContent>
          <Login />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("close_btn")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
