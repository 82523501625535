import { Divider, Grid, MenuItem, Paper } from "@mui/material";
import { Box, color } from "@mui/system";
import React, { useEffect, useState } from "react";
// import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
// import { notify } from "../../../components/common/toast";
// import Spinner from "../../../components/common/Spinner";
// import ToolBar from "../../../components/common/ToolBar";
import Input from "../controls/Input";

const CustomerCreateForm = () => {
  const navigate = useNavigate();
  // const [saveError, setSaveError] = useState();
  const [inititalState] = useState({
    PERSON_NAME: null,
    LAT_NAME: null,
    F_NAME: null,
    BIRTH_DATE: null,
    NAT_ID_NO: null,
    PASP_NO: null,
    GVR_CODE: null,
    SEC_CODE: null,
    STREET_NAME: null,
    TEL_NO: null,
    MOBILE_NO1: null,
    MOBILE_NO2: null,
    FAX_NO: null,
    EMAIL: null,
    NATIONALITY: null,
    RELG_CODE: null,
    GENDER_CODE: null,
    CUST_TYPE: null,
    CUST_SECTION: null,
    COMM_REC_NO: null,
    TAX_CARD_NO: null,
    REL_PERSONID: null,
    COMMENTS: null,
    REC_USER: 1,
    UPD_USER: 1,
    BIRTH_PLACE: null,
  });

  const [customer, setCustomer] = useState({ ...inititalState });

  // const { custTypes, nationalities, governorates, goverSection } =
  //   useGetCustomerLookupsQuery(undefined, {
  //     selectFromResult: (x) => ({
  //       custTypes: x.data.custTypes,
  //       nationalities: x.data.nationalities,
  //       governorates: x.data.governorates,
  //       goverSection: x.data.goverSection,
  //     }),
  //   });

  // const [createCustomer, { isLoading: saveLoading, error: saveError }] =
  //   useCreateCustomerMutation();

  // const { errors, validateForm, onBlurField } = useCustomerFormValidator(
  //   inititalState,
  //   customer
  // );

  //IsDirty
  // const getDirtyFields = (customer) =>
  //   Object.keys(customer).reduce((acc, key) => {
  //     // check all form fields that have changed
  //     const isDirty = customer[key] !== "";

  //     return { ...acc, [key]: isDirty };
  //   }, {});

  // const dirtyFields = getDirtyFields(customer);

  // const hasChanges = Object.values(dirtyFields).reduce((acc, currVal) => {
  //   currVal === true ? (acc = true) : acc == acc;
  //   return acc;
  // }, false);

  const onFieldChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const nextFormState = {
      ...customer,
      [field]: value,
    };
    setCustomer(nextFormState);
    // if (errors[field].dirty)
    //   validateForm({
    //     currState: nextFormState,
    //     errors,
    //     field,
    //   });
  };

  // const onSaveCustomerClicked = async () => {
  //   const { isValid } = validateForm({
  //     currState: customer,
  //     errors,
  //     forceTouchErrors: true,
  //   });
  //   if (!isValid) {
  //     notify("فشلت عملية الحفظ", 0);
  //   } else {
  //     const result = await createCustomer(customer);
  //     console.log("Saving customer result: ", result);
  //     console.log("saveError: ", saveError);
  //     if (result.error) {
  //       // console.log("saving failed: ", result.data.status);
  //       // setSaveError(result.error);
  //       notify("Saving Failed", 0);
  //     } else {
  //       // console.log("saving success: ", result.data.status);
  //       setCustomer({ ...inititalState });
  //       notify("تمت عملية الحفظ بنجاح", 1);
  //     }
  //   }
  //   // console.log("savedPerson", data.R_PERSONID);
  //   // navigate(`/sales/customers/${data.R_PERSONID}`, {
  //   //   state: { viewType: "edit" },
  //   // });
  // };

  /// Save Dialog ///
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClickClose = () => {
    setDialogOpen(false);
  };
  /// END Save Dialog ///

  let content;
  if (
    Object.keys(customer).length === 0
    // ||
    // Object.keys(errors).length === 0
    // || isFetching
  ) {
    content = <p>Loading...</p>;
  } else {
    content = (
      <form onSubmit={handleDialogClickOpen}>
        <Box sx={{ width: "100%", p: 2, pt: 4 }}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={2} lg={1}>
              <Input
                required
                disabled
                id="id"
                name="PERSONID"
                label={<FormattedMessage id="code" defaultMessage="Code" />}
                variant="outlined"
                fullWidth
                value={customer.PERSONID}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <Input
                required
                id="name"
                name="PERSON_NAME"
                label={"Name"}
                variant="outlined"
                fullWidth
                value={customer.PERSON_NAME}
                onChange={(e) => onFieldChange(e)}
                // onBlur={(e) => onBlurField(e)}
                // error={errors.PERSON_NAME.message}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Input
                select
                id="type"
                name="CUST_TYPE"
                label={"Customer Type"}
                variant="outlined"
                fullWidth
                // value={customer.CUST_TYPE ? customer.CUST_TYPE : -1}
                value={customer.CUST_TYPE}
                onChange={(e) => onFieldChange(e)}
              >
                {/* {custTypes?.map((option, index) => (
                  // console.log("option: " + JSON.stringify(option))
                ))} */}
                <MenuItem key={1} value={1}>
                  Consumer
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Corporate
                </MenuItem>
              </Input>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Input
                required
                id="birthdate"
                name="BIRTH_DATE"
                label={"Birthdate"}
                variant="outlined"
                fullWidth
                value={customer.BIRTH_DATE}
                onChange={(e) => onFieldChange(e)}
                // inputProps={{ onBlur: (e) => onBlurField(e) }}
                // onBlur={(e) => onBlurField(e)}
                // // error={errors.BIRTH_DATE.dirty && errors.BIRTH_DATE.error}
                // error={errors.BIRTH_DATE.message}
                // inputProps={{ onBlur: onBlurField }}

                // helperText="helper text"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Input
                required
                select
                id="nationality"
                name="NATIONALITY"
                label={"Nationality"}
                variant="outlined"
                fullWidth
                value={customer.NATIONALITY}
                onChange={(e) => onFieldChange(e)}
                // onBlur={(e) => onBlurField(e)}
                // error={errors.NATIONALITY.message}
              >
                {/* {nationalities?.map((option, index) => (
                  // console.log("option: " + JSON.stringify(option))
                ))} */}
                <MenuItem key={1} value={1}>
                  Egypt
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Saudi Arabia
                </MenuItem>
                <MenuItem key={3} value={3}>
                  Jordan
                </MenuItem>
              </Input>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Input
                required
                select
                name="GVR_CODE"
                id="governorate"
                label={"Governorate"}
                variant="outlined"
                fullWidth
                value={customer.GVR_CODE}
                onChange={(e) => onFieldChange(e)}
                // onBlur={(e) => onBlurField(e)}
                // error={errors.GVR_CODE.message}
                // style={{ color: "red" }}
                // inputProps={{ style: { color: "red" } }}
              >
                {/* {governorates?.map((option, index) => (
                  // console.log("option: " + JSON.stringify(option))
                ))} */}
                <MenuItem key={1} value={1}>
                  Cairo
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Giza
                </MenuItem>
              </Input>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <Input
                required
                select
                name="SEC_CODE"
                id="sectionCode"
                label={
                  <FormattedMessage id="sectionCode" defaultMessage="Section" />
                }
                variant="outlined"
                fullWidth
                value={customer.SEC_CODE}
                onChange={(e) => onFieldChange(e)}
                onBlur={(e) => onBlurField(e)}
                error={errors.SEC_CODE.message}
              >
                {goverSection
                  ?.filter((option) => option.pCode === customer.GVR_CODE)
                  .map((option, index) => (
                    // console.log("option: " + JSON.stringify(option))
                    <MenuItem key={index} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Input>
            </Grid> */}
            <Grid item xs={12} md={6} lg={3}>
              <Input
                required
                id="nationalId"
                name="NAT_ID_NO"
                label={"National ID"}
                variant="outlined"
                fullWidth
                value={customer.NAT_ID_NO}
                onChange={(e) => onFieldChange(e)}
                // onBlur={(e) => onBlurField(e)}
                // error={errors.NAT_ID_NO.message}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Input
                id="passportNo"
                name="PASP_NO"
                label={"Passport No"}
                variant="outlined"
                fullWidth
                value={customer.PASP_NO}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Input
                id="mobile1"
                name="MOBILE_NO1"
                label={"Mobile 1"}
                variant="outlined"
                fullWidth
                value={customer.MOBILE_NO1}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Input
                id="telephone"
                name="TEL_NO"
                label={"Telephone"}
                variant="outlined"
                fullWidth
                value={customer.TEL_NO}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Input
                id="fax"
                name="FAX_NO"
                label={"Fax"}
                variant="outlined"
                fullWidth
                value={customer.FAX_NO}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Input
                id="email"
                name="EMAIL"
                label={"Email"}
                variant="outlined"
                fullWidth
                value={customer.EMAIL}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Input
                id="TaxCard"
                name="TAX_CARD_NO"
                label={"Tax Card"}
                variant="outlined"
                fullWidth
                value={customer.TAX_CARD_NO}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Input
                id="CommRec"
                name="COMM_REC_NO"
                label={"Commercial Record"}
                variant="outlined"
                fullWidth
                value={customer.COMM_REC_NO}
                onChange={(e) => onFieldChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <Input
                required
                id="address"
                name="STREET_NAME"
                label={"Address"}
                variant="outlined"
                fullWidth
                value={customer.STREET_NAME}
                onChange={(e) => onFieldChange(e)}
                // onBlur={(e) => onBlurField(e)}
                // error={errors.STREET_NAME.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Input
                id="comments"
                name="COMMENTS"
                label={"Comments"}
                variant="outlined"
                fullWidth
                value={customer.COMMENTS}
                onChange={(e) => onFieldChange(e)}
                multiline
                rows={4}
                // sx={{ color: "white" }}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }

  return (
    <Paper sx={{ backgroundColor: "#00000020", pt: 0 }}>
      <Divider />
      {content}
      {/* {saveLoading && <LoadingDialog isLoading={saveLoading} />} */}
      {/* {true && <Spinner />} */}
    </Paper>
  );
};

export default CustomerCreateForm;
