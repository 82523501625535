import axios from "axios";

const API_URL = `${process.env.REACT_APP_NODE_API}/timeSlots`;

export const getAllSlots = (token) => {
  return axios.get(`${API_URL}`, {
    headers: { Authorization: `Bearer ${token}` },
    // params: { date },
  });
};

export const getAvailableSlots = (token, date) => {
  return axios.get(`${API_URL}/available`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { date },
  });
};

export const updateSlotAvailability = (token, slot) => {
  return axios.put(`${API_URL}/availability`, slot, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
