import { Box, Grid, TextField } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./InstCentersStyle.css";
import centersImg from "../../imgs/InstallationCenters.png";

import { Context } from "../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { centers } from "./CentersData";

const colors = {
  bg: {
    accordionColor: "#00000000",
    summaryColor: "#1d1d1dc3",
    detailsColor: "#ffffffc3",
  },
};
function InstCenters() {
  const context = useContext(Context);
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
      }}
      dir={context.dir}
    >
      {/*centers welcome section */}
      <Box
        container
        className="centers-welcome-container"
        sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block" } }}
      >
        <img src={centersImg} />
      </Box>

      {/*centers main section */}
      <Box container className="centers-main-container">
        <h1> {t("centers_cairo")}</h1>

        <Grid container spacing={4}>
          {centers.cairo.map((center) => (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Accordion
                expanded={expanded === center.phone1}
                onChange={handleChange(center.phone1)}
                sx={{ backgroundColor: colors.bg.accordionColor }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#ff8c05" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="accordion-header"
                  sx={{ backgroundColor: colors.bg.summaryColor }}
                >
                  <h2 sx={{ width: "100%", flexShrink: 0 }}>
                    {center.name} <p> {center.city}</p>{" "}
                  </h2>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: colors.bg.detailsColor }}
                >
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextInputData
                        lable="نوع المركبه "
                        val={center.carType}
                      />
                      <TextInputData lable=" 1 عنوان " val={center.address1} />
                      {center.address2 && (
                        <TextInputData
                          lable=" 2 عنوان "
                          val={center.address2}
                        />
                      )}
                      {center.director && (
                        <TextInputData
                          lable="المدير المسؤول "
                          val={center.director}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextInputData
                        lable="هاتف "
                        val={
                          center.phone2
                            ? center.phone1 + " - " + center.phone2
                            : center.phone1
                        }
                      />
                      {center.fax && (
                        <TextInputData lable="فاكس " val={center.fax} />
                      )}
                      {center.email && (
                        <TextInputData lable="إيميل " val={center.email} />
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
        <h1> {t("centers_other_govs")}</h1>
        <Grid container spacing={4}>
          {centers.otherGovs.map((center) => (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Accordion
                expanded={expanded === center.phone1}
                onChange={handleChange(center.phone1)}
                sx={{ backgroundColor: colors.bg.accordionColor }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#ff8c05" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="accordion-header"
                  sx={{ backgroundColor: colors.bg.summaryColor }}
                >
                  <h2 sx={{ width: "100%", flexShrink: 0 }}>
                    {center.name} <p> {center.city}</p>{" "}
                  </h2>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: colors.bg.detailsColor }}
                >
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextInputData
                        lable="نوع المركبه "
                        val={center.carType}
                      />
                      <TextInputData lable=" 1 عنوان " val={center.address1} />
                      {center.address2 && (
                        <TextInputData
                          lable=" 2 عنوان "
                          val={center.address2}
                        />
                      )}
                      {center.director && (
                        <TextInputData
                          lable="المدير المسؤول "
                          val={center.director}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextInputData
                        lable="هاتف "
                        val={
                          center.phone2
                            ? center.phone1 + " - " + center.phone2
                            : center.phone1
                        }
                      />
                      {center.fax && (
                        <TextInputData lable="فاكس " val={center.fax} />
                      )}
                      {center.email && (
                        <TextInputData lable="إيميل " val={center.email} />
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
        <h1>{t("centers_agencies")}</h1>
        <Grid container spacing={4}>
          {centers.agents.map((center) => (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Accordion
                expanded={expanded === center.phone1}
                onChange={handleChange(center.phone1)}
                sx={{ backgroundColor: colors.bg.accordionColor }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#ff8c05" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="accordion-header"
                  sx={{ backgroundColor: colors.bg.summaryColor }}
                >
                  <h2 sx={{ width: "100%", flexShrink: 0 }}>{center.name}</h2>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: colors.bg.detailsColor }}
                >
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextInputData
                        lable="نوع المركبه "
                        val={center.carType}
                      />
                      <TextInputData lable=" 1 عنوان " val={center.address1} />
                      {center.address2 && (
                        <TextInputData
                          lable=" 2 عنوان "
                          val={center.address2}
                        />
                      )}
                      {center.address3 && (
                        <TextInputData
                          lable=" 3 عنوان "
                          val={center.address3}
                        />
                      )}
                      {center.address4 && (
                        <TextInputData
                          lable=" 4 عنوان "
                          val={center.address4}
                        />
                      )}
                      {center.address5 && (
                        <TextInputData
                          lable=" 5 عنوان "
                          val={center.address5}
                        />
                      )}
                      {center.director && (
                        <TextInputData
                          lable="المدير المسؤول "
                          val={center.director}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextInputData
                        lable="هاتف "
                        val={
                          center.phone2
                            ? center.phone1 + " - " + center.phone2
                            : center.phone1
                        }
                      />
                      {center.fax && (
                        <TextInputData lable="فاكس " val={center.fax} />
                      )}
                      {center.email && (
                        <TextInputData lable="إيميل " val={center.email} />
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

const TextInputData = (props) => {
  return (
    <TextField
      id="standard-read-only-input"
      label={props.lable}
      defaultValue={props.val}
      InputProps={{
        readOnly: true,
        // sx:{color:'#ff8c05'}
      }}
      variant="standard"
      style={{ marginBottom: "5px", width: "95%" }}
      multiline
      sx={{
        "& label": {
          width: "130%",
          display: "flex",
          justifyContent: "right",
          fontSize: "18px",
          fontWeight: "bold",
        },
      }}
    />
  );
};

export default InstCenters;
