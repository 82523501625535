import { Box, Grid } from "@mui/material";
import React from "react";

import "./ServicesDetailsStyle.css";
import fleetServImg from "../../imgs/services/tracking_images/GPS_Fleet_Tracking.jpg";
import fleetServImg2 from "../../imgs/services/tracking_images/fleet.png";

import assitsTrackingImg from "../../imgs/services/tracking_images/asset_tracking1.jpg";
import assitsTrackingImg2 from "../../imgs/services/tracking_images/asset-tracking.png";
import assitsTrackingImg3 from "../../imgs/services/tracking_images/asset-tracking2.png";

import personalTrackingImg1 from "../../imgs/services/tracking_images/personalTracking.jpg";
import personalTrackingImg2 from "../../imgs/services/tracking_images/personalTracking2.jpg";

import vehicleTrackingImg from "../../imgs/services/tracking_images/tracking.jpg";
import vehicleTrackingImg2 from "../../imgs/services/tracking_images/vehicle-tracking-1.jpg";

import OfflinePinIcon from "@mui/icons-material/OfflinePin";
// import { HashLink } from "react-router-hash-link";

import { Context } from "../LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CommonSlider from "../commonSlider/CommonSlider";

function TrackingServicesDetails() {
  const context = useContext(Context);
  const { t } = useTranslation();

  const fleetImagesArray = [fleetServImg, fleetServImg2];
  const assetsImagesArray = [
    assitsTrackingImg2,
    assitsTrackingImg,
    assitsTrackingImg3,
  ];
  const personalImagesArray = [personalTrackingImg1, personalTrackingImg2];
  const vehicaleImagesArray = [vehicleTrackingImg, vehicleTrackingImg2];
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        p: 3,
        pt: "100px",
      }}
    >
      <h1 className="service-header">{t("tracking_services")}</h1>

      <Box sx={{ flexGrow: 1, color: "white" }}>
        {/****************** fleet management system */}
        <Grid sx={{ p: "20px 5px" }} container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-det-container"
          >
            <CommonSlider
              className="fleet-serv-det-img"
              imgs={fleetImagesArray}
            />
            {/* <img
              src={fleetServImg}
              alt="tracking"
              className="fleet-serv-det-img"
            /> */}
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px",
                padding: "0px",
              }}
            >
              {context.dir === "ltr" ? (
                <h1 className="serv-subtitle" id="fleet">
                  <span>{t("serv_fleet_t_bold")}</span>
                  {t("serv_fleet_t_normal")}
                </h1>
              ) : (
                <h1 className="serv-subtitle" id="fleet">
                  {t("serv_fleet_t_normal")}
                  <span>{t("serv_fleet_t_bold")}</span>
                </h1>
              )}

              <p className="serv-paragraph">{t("serv_fleet_p")}</p>
              <ul className="serv-list-item" dir={context.dir}>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_1")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_2")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_3")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_4")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_5")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_6")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_7")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_fleet_li_8")}
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        {/******************* assets tracking */}
        <Grid sx={{ p: "20px 5px" }} container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-det-container"
            sx={{ display: { xs: "flex", sm: "none", md: "none", lg: "none" } }}
          >
            <CommonSlider
              className="fleet-serv-det-img"
              imgs={assetsImagesArray}
            />
            {/* <img
              src={vehicleTrackingImg}
              alt="tracking"
              className="fleet-serv-det-img"
            /> */}
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "10% 0px",
              }}
            >
              {context.dir === "ltr" ? (
                <h1 className="serv-subtitle" id="assets">
                  <span>{t("serv_assets_t_bold")}</span>
                  {t("serv_assets_t_normal")}
                </h1>
              ) : (
                <h1 className="serv-subtitle" id="assets">
                  {t("serv_assets_t_normal")}
                  <span>{t("serv_assets_t_bold")}</span>
                </h1>
              )}

              <p className="serv-paragraph">{t("serv_assets_p")}</p>

              <ul className="serv-list-item" dir={context.dir}>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />{" "}
                  {t("serv_det_assets_li_1")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />{" "}
                  {t("serv_det_assets_li_2")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />{" "}
                  {t("serv_det_assets_li_3")}
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-det-container"
            sx={{ display: { xs: "none", sm: "flex", md: "flex", lg: "flex" } }}
          >
            <CommonSlider
              className="fleet-serv-det-img"
              imgs={assetsImagesArray}
            />
          </Grid>
        </Grid>

        {/****************** personal tracking */}
        <Grid sx={{ p: "20px 5px" }} container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-det-container"
          >
            <CommonSlider
              className="fleet-serv-det-img"
              imgs={personalImagesArray}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "10% 0px",
              }}
            >
              {context.dir === "ltr" ? (
                <h1 className="serv-subtitle" id="personal">
                  <span>{t("serv_personal_t_bold")}</span>
                  {t("serv_personal_t_normal")}
                </h1>
              ) : (
                <h1 className="serv-subtitle" id="personal">
                  {t("serv_personal_t_normal")}
                  <span>{t("serv_personal_t_bold")}</span>
                </h1>
              )}

              <p className="serv-paragraph">{t("serv_personal_p")}</p>
              <ul className="serv-list-item" dir={context.dir}>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_personal_li_1")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_personal_li_2")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_personal_li_3")}
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        {/******************* vehicale tracking */}
        <Grid sx={{ p: "20px 5px" }} container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-det-container"
            sx={{ display: { xs: "flex", sm: "none", md: "none", lg: "none" } }}
          >
            <CommonSlider
              className="fleet-serv-det-img"
              imgs={vehicaleImagesArray}
            />
            {/* <img
              src={vehicleTrackingImg}
              alt="tracking"
              className="fleet-serv-det-img"
            /> */}
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "10% 0px",
              }}
            >
              {context.dir === "ltr" ? (
                <h1 className="serv-subtitle" id="vehicle">
                  <span>{t("serv_vehicle_t_bold")}</span>
                  {t("serv_vehicle_t_normal")}
                </h1>
              ) : (
                <h1 className="serv-subtitle" id="vehicle">
                  {t("serv_vehicle_t_normal")}
                  <span>{t("serv_vehicle_t_bold")}</span>
                </h1>
              )}

              <p className="serv-paragraph">{t("serv_vehicle_p")}</p>

              <ul className="serv-list-item" dir={context.dir}>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />{" "}
                  {t("serv_det_vehicle_li_1")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />{" "}
                  {t("serv_det_vehicle_li_2")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />{" "}
                  {t("serv_det_vehicle_li_3")}
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-det-container"
            sx={{ display: { xs: "none", sm: "flex", md: "flex", lg: "flex" } }}
          >
            <CommonSlider
              className="fleet-serv-det-img"
              imgs={vehicaleImagesArray}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TrackingServicesDetails;
