import React, { useContext } from "react";

import SpeedIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";
import CommuteIcon from "@mui/icons-material/Commute";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import { t } from "i18next";

import "./WhyUsStyle.css";
// import whyUsImg from "../../imgs/etit_logo.png";
import whyUsImg from "../../imgs/videos/etit1.mp4";
import { SpeedDialIcon } from "@mui/material";
import { Context } from "../LocalWrapper";

function WhyUs() {
  const context = useContext(Context);

  //   console.log("context : ", context);

  return (
    <div dir={context.dir} class="container-fluid py-6 px-5">
      <div class="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
        <h1 class="display-5 mb-0">{t("why_header")}</h1>
        <hr class="w-25 mx-auto bg-primary" />
      </div>
      <div class="row g-5">
        <div class="col-lg-4">
          <div class="row g-5">
            <div class="item col-12">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <SpeedIcon fontSize="large" className="icon" />
              </div>
              <h3>{t("why_t_1")}</h3>
              <p class="mb-0">{t("why_p_1")}</p>
            </div>
            <div class="item col-12">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <SecurityIcon fontSize="large" className="icon" />
              </div>
              <h3>{t("why_t_2")}</h3>
              <p class="mb-0">{t("why_p_2")}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="d-block bg-primary h-100 text-center">
            {/* <img class="img-fluid" src={whyUsImg} alt="" /> */}

            <video
              class="img-fluid"
              autoPlay
              muted
              loop
              //   className="slide-image"
            >
              <source src={whyUsImg} type="video/mp4" />
            </video>
            <div class="p-4">
              <p class="why-middle-desc text-white mb-4">
                {t("why_middle_desc")}
              </p>
              <a
                href="/about"
                class="btn btn-dark py-md-3 px-md-5 rounded-pill mb-2"
              >
                {t("nav_about")}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row g-5">
            <div class="item col-12">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <CommuteIcon fontSize="large" className="icon" />
              </div>
              <h3>{t("why_t_3")}</h3>
              <p class="mb-0">{t("why_p_3")}</p>
            </div>
            <div class="item col-12">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <SupportAgentIcon fontSize="large" className="icon" />
              </div>
              <h3>{t("why_t_4")}</h3>
              <p class="mb-0">{t("why_p_4")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
