import * as React from "react";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import Typography from "@mui/material/Typography";
import { notify } from "../../theme/notification";

import LanguageIcon from "@mui/icons-material/Language";

import etitLogo from "../../imgs/etit_logo.png";

import { Button, Menu, MenuItem } from "@mui/material";
// import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import "./NavbarStyle.css";

import { Context } from "../LocalWrapper";
import { useContext } from "react";
import i18next from "i18next";
import { useEffect } from "react";
import { useState } from "react";
import SideDrawer from "./SideDrawer";
import AccountMenu from "./AccountMenu";
import Topbar from "./topbar/Topbar";
import PublicNotification from "../publicNotification/PublicNotification";
// import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // const navigate = useNavigate();
  const context = useContext(Context);
  const { t } = useTranslation();

  const changeLang = (code) => {
    i18next.changeLanguage(code);
    // alert(context.currentLang.code)
    window.location.reload(true);
  };

  const navItems = [
    { id: "home", name: t("nav_home"), path: "/" },
    // { name: t("nav_products"), path: "/#products" },
    {
      id: "services",
      name: t("nav_services"),
      // path: "/services-det",
      children: [
        { id: 1, name: t("tracking_services"), path: "/tracking-services-det" },
        {
          id: 2,
          name: t("system_integration_services"),
          path: "/system-integration-services-det",
        },
        {
          id: 3,
          name: t("development_services"),
          path: "/software-development-services-det",
        },
        // { id: 4, name: t("it_services"), path: "/" },
        { id: 5, name: t("network_services"), path: "/network-services-det" },
        { id: 7, name: t("cloud_services"), path: "/cloud-services-det" },
        {
          id: 8,
          name: t("cyber_security_services"),
          path: "/cyber-security-services-det",
        },
      ],
    },
    // { name: t("nav_docs"), path: "/documents" },
    // { name: t("nav_centers"), path: "/centers" },
    { id: "contact", name: t("nav_contact"), path: "/#contact" },
    { id: "about", name: t("nav_about"), path: "/about" },
    // { id: "login", name: t("nav_login"), path: "/login" },
    {
      name: t("nav_apps"),
      path: "/applications",
      id: "apps",
    },
    // {
    //   name: t("nav_apps"),
    //   path: "https://etit-fms.etit-eg.com/",
    //   id: "fms_login",
    // },
  ];
  // console.log("xontext : ", context.user.email);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <Box
      sx={
        {
          // display: "flex",
        }
      }
      dir={context.dir}
    >
      <CssBaseline />

      <>
        {/* nav container */}
        <Box
          className={`nav-bar }`}
          sx={{ display: "flex", flexDirection: "column", padding: "0px" }}
        >
          <Box>{!isScrolled ? <Topbar /> : ""}</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              // height: "60px",
              justifyContent: "space-between",
              padding: "0px 20px",
              // backgroundColor: "red",
            }}
          >
            {/* left side */}
            <Box
              sx={{
                padding: "0px",
                margin: "0px",
                // backgroundColor: "green",
              }}
            >
              <a href="/" style={{ textAlign: "left", width: "70px" }}>
                <img
                  src={etitLogo}
                  alt="ETIT LOGO"
                  style={{ height: "50px", width: "70px" }}
                />
              </a>
            </Box>

            {/* middle side */}

            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
                // padding: "0px",
                // margin: "0px",
                // // height: "30px",
                // backgroundColor: "green",
              }}
            >
              <ul
                className={`nav-links`}
                style={{ padding: "0px", margin: "0px" }}
              >
                {navItems.map((item) =>
                  !item.children ? (
                    <li key={item.name}>
                      <a
                        href={item.path}
                        // target={item.id === "login" ? "_blank" : ""}
                        rel="noreferrer"
                      >
                        {item.name}
                      </a>
                    </li>
                  ) : (
                    <div
                      className="dropdown btn-group services-dropdown"
                      key={item.name}
                    >
                      <button
                        className="btn dropdown-toggle"
                        style={{
                          color: "var(--dark)",
                          padding: "0px",
                          border: "0px",
                        }}
                        // type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="services-dropdown-btn-txt">
                          {item.name}
                        </span>
                      </button>
                      <ul className="dropdown-menu">
                        {item.children.map(({ id, name, path }) => (
                          <li key={id}>
                            <a
                              className={"services-dropdown-menu-link"}
                              href={path}
                              key={id}
                              // smooth
                              rel="noreferrer"
                            >
                              {name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                )}
              </ul>
            </Box>

            {/* right side */}
            <Box
              sx={{
                display: "flex",
                // flex: 2,
                justifyContent: "end",
                // bgcolor: "green",
                margin: "0px",
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                  },
                }}
              >
                <div
                  className="d-flex justify-content-center et-nav-link"
                  style={{
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  <div
                    className="dropdown"
                    style={{
                      padding: "0px",
                      margin: "0px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {/* <Button
                    sx={{ margin: "0px 20px", backgroundColor: "#ff8c05" }}
                    variant="contained"
                    href="/reservation"
                  >
                    Reserve Now
                  </Button> */}

                    <button
                      className="btn dropdown-toggle"
                      style={{ color: "var(--dark)", padding: "0px" }}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <LanguageIcon sx={{ width: "20px", height: "20px" }} />{" "}
                      {context.currentLang.code}
                    </button>
                    <ul className="dropdown-menu">
                      {context.languages.map(({ code, name, country_code }) => (
                        <li key={code}>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              changeLang(code);
                              // setlangCode(code);
                              // window.location.reload(true);
                            }}
                          >
                            <span
                              className={`fi fi-${country_code} mx-2`}
                            ></span>
                            {name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Box>
              <AccountMenu />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 0,
                  display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
                  // width:'30px',height:'30px'
                  color: "var('--dark')",
                }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
          <PublicNotification />
        </Box>
      </>

      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#161616",
              color: "#fff",
            },
          }}
        >
          <SideDrawer
            handleDrawerToggle={handleDrawerToggle}
            navItems={navItems}
            etitLogo={etitLogo}
            context={context}
            changeLang={changeLang}
          />
        </Drawer>
      </Box>
    </Box>
  );
}
