import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.js";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Flags CSS
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./bootstrap.min.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import "./App.css";
import { LocalWrapper } from "./components/LocalWrapper";
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["EN", "AR"],
    fallbackLng: "EN",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const loadingMarkup = (
  <div
    className="py-4 text-center App"
    style={{ height: "100vh", color: "#ff8c05" }}
  >
    <h2>Loading..</h2>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={loadingMarkup}>
    <LocalWrapper>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LocalWrapper>
  </Suspense>
);
