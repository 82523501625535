import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import docTitleImg_L from "../../imgs/docs_l.png";
import docTitleImg_R from "../../imgs/docs_r.png";
import "./DocumentsStyle.css";

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Context } from "../LocalWrapper";

function Documents() {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        pt: 8,
      }}
    >
      <h1 className="docs-header">{t("docs_header")}</h1>
      <Grid container sx={{ p: "20px 5px", backgroundColor: "#2c3e50" }}>
        <Grid item xs={11} sm={9} md={9} lg={9}>
          <Box className="docs-det-container" dir={context.dir}>
            <h1 className="docs-title-light">
              <span> {t("docs_corp_4x4_title_bold")} </span>
              {t("docs_corp_4x4_title")}
            </h1>

            <ul className="list-item-light">
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_4x4_li_1")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_4x4_li_2")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_4x4_li_3")}
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={1} sm={3} md={3} lg={3} className="docs-arrow r-arrow">
          <img src={docTitleImg_R} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1} sm={3} md={3} lg={3} className="docs-arrow l-arrow">
          <img src={docTitleImg_L} />
        </Grid>
        <Grid item xs={11} sm={9} md={9} lg={9}>
          <Box className="docs-det-container" dir={context.dir}>
            <h1 className="docs-title">
              <span> {t("docs_consumer_title_bold")} </span>
              {t("docs_consumer_title")}
            </h1>

            <ul className="list-item">
              <li>
                <DocumentScannerIcon className="list-item-icon" />
                {t("docs_consumer_li_1")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />
                {t("docs_consumer_li_2")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />
                {t("docs_consumer_li_3")}
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ p: "20px 5px", backgroundColor: "#2c3e50" }}>
        <Grid item xs={11} sm={9} md={9} lg={9}>
          <Box className="docs-det-container" dir={context.dir}>
            <h1 className="docs-title-light">
              <span>{t("docs_consumer_waiver_title_bold")}</span>{" "}
              {t("docs_consumer_waiver_title")}
            </h1>
            <ul className="list-item-light">
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_consumer_waiver_li_1")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_consumer_waiver_li_2")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_consumer_waiver_li_3")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_consumer_waiver_li_4")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_consumer_waiver_li_5")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_consumer_waiver_li_6")}
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={1} sm={3} md={3} lg={3} className="docs-arrow r-arrow">
          {/* <AttachFileIcon
            className="list-item-icon attach-icon"
            sx={{ fontSize: "15vw" }}
          /> */}
          <img src={docTitleImg_R} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1} sm={3} md={3} lg={3} className="docs-arrow l-arrow">
          <img src={docTitleImg_L} />
        </Grid>

        <Grid item xs={11} sm={9} md={9} lg={9}>
          <Box className="docs-det-container" dir={context.dir}>
            <h1 className="docs-title">
              <span> {t("docs_corp_waiver_title_bold")} </span>{" "}
              {t("docs_corp_waiver_title")}
            </h1>
            <ul className="list-item">
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_waiver_li_1")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_waiver_li_2")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_waiver_li_3")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_waiver_li_4")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_waiver_li_5")}
              </li>
              <li>
                <DocumentScannerIcon className="list-item-icon" />{" "}
                {t("docs_corp_waiver_li_6")}
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Documents;
