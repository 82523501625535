export const centers = {
  cairo: [
    {
      name: "الشركة المصرية لخدمات التتبع وتكنولوجيا المعلومات - ETIT",
      carType: "جميع المركات / All models",
      city: '6أكتوبر',
      address1:
        "مبنى رقم 145 – القرية الذكية – الكيلو 28 طريق مصر اسكندرية الصحراوى - الجيزة",
      address2: "",
      director: "",
      phone1: "19382",
      phone2: "",
      fax: "",
      email: "Call.center@etit-eg.com",
    },
    {
      name: "GEBCO",
      carType: "جميع المركات / All models",
      city: 'الشيخ زايد / مدينة نصر',
      address1: ` فيلا 50 , الحي السابع , المجاورة الربعة - الشيخ ذايد `,
      address2: " ميدان المستشار هشام بركات , مدية نصر",
      director: "م/ أشرف جودة",
      phone1: "01022999381",
      phone2: "0238507018",
      fax: "0238241241",
      email: "ashraf.gouda@gebco-sys.com",
    },
    {
      name: "شركة اند - لس كريشن",
      carType: "جميع المركات / All models",
      city: 'مصر الجديدة',
      address1: `46 شارع عثمان بن عفان - ميدان الاسماعيلية - مصرالجديدة - القاهرة`,
      address2: "",
      director: "م / سامر فكرى أيوب",
      phone1: "01001230501",
      phone2: "",
      fax: "",
      email: "sayoub@endless-creations.com",
    },
    {
      name: `انفينتي - infinity`,
      carType: "جميع المركات / All models",
      city: 'المعادى',
      address1: `7أ حي العاملين زهراء المعادي - شارع الخمسين امام فودافون زهراء المعادي`,
      address2: "",
      director: "م/ محمد زيدان",
      phone1: "01021111480",
      phone2: "",
      fax: "",
      email: "gps@infinity-egy.net",
    },
    {
      name: "امير و جوزيف",
      carType: `جميع المركات/  ستروين -  بروتون
        All models / Citroen - Proton`,
        city: 'مدينة نصر',
      address1: `ش34 ابو داود الظاهرى -مكرم عبيد - مدينه نصر`,
      address2: "",
      director: "م/ جوزيف الفى",
      phone1: "01002151986",
      phone2: "",
      fax: "",
      email: "",
    },
    {
      name: "اتقان اوتوسيرفيس",
      carType: "جميع المركات / All models",
      city: 'اكتوبر6',
      address1: `6 اكتوبر – الحى الثالث – 979 شارع رنا مول`,
      address2: "",
      director: "م / عمرو شبراوي مصطفى",
      phone1: "01091952051",
      phone2: "01114545244",
      fax: "",
      email: "Itqan.autoservice@gmail.com",
    },
    {
      name: "Tree Limousine",
      carType: "جميع المركات / All models",
      city: 'اكتوبر6',
      address1: `ك 28 طريق مصر اسكندرية - شارع ترى سنتر بجوار Mitsubishi`,
      address2: "",
      director: "ا / خالد عبد الستار",
      phone1: "01001670004",
      phone2: "01001070070",
      fax: "7608299",
      email: "treelimousine@hotmail.com",
    },
    {
      name: "Appliance",
      carType: "جميع المركات / All models",
      city: 'مدينة نصر',
      address1: `16 شارع ابو العتاهيه امتداد عباس العقاد امام الحديقة الدولية`,
      address2: "",
      director: "م/ إسماعيل مصطفى الجندى",
      phone1: "01271600068",
      phone2: "",
      fax: "26735553",
      email: "gps@appliance-eg.com",
    },
    {
      name: "مركز قرطبة - اشرفكو",
      carType: "جميع المركات / All models",
      city: 'القاهره / المهندسين',
      address1: `6 عمارات الاوقاف ـ ميدان السواح ـ امام القصر الجمهورى - القاهرة`,
      address2: `205 شارع 26 يوليو ناصية شارع احمد عرابي - سفنكس - المهندسين`,
      director: "",
      phone1: "01115401155",
      phone2: "01144000640",
      fax: "",
      email: "abdelaty77@yahoo.com",
    },
    {
      name: "الامين للانظمة المتطورة",
      carType: "جميع المركات / All models",
      city: 'مصر الجديده',
      address1: `عمارات الميريلاند-شارع جسر السويس-عمارة 6-مصر الجديدة`,
      address2: "",
      director: "أ/ عبد الله امين",
      phone1: "01220202865",
      phone2: "",
      fax: "",
      email: "abdallah.amin@alamintech.com",
    },
    {
      name: "مودرن سيرفيس جروب",
      carType: "جميع المركات / All models",
      city: 'النزهة الجديدة',
      address1: `15 شارع جوزيف تيتو طريق السندباد - النزهة الجديدة`,
      address2: "",
      director: "م / مجدي خلف",
      phone1: "01200009065",
      phone2: "01200001179",
      fax: "",
      email: "modernserivce@hotmail.com",
    },
    {
      name: "Tune-It Up - تيون ات اب",
      carType: "جميع المركات / All models",
      city: 'الهرم',
      address1: `4 ش منتصر بن طارق – تعاون - الهرم`,
      address2: "",
      director: "م/ محمد ابراهيم",
      phone1: "01000930450",
      phone2: "",
      fax: "0235866675",
      email: "info@tune-itup.com",
    },
    {
      name: "سبيد لاين SpeedLine",
      carType: "جميع المركات / All models",
      city: 'مدينة نصر',
      address1: `26 شارع نور الدين بهجت من مكرم عبيد - مدينة نصر - القاهرة`,
      address2: "",
      director: "أ/ عبد الله همام",
      phone1: "01155257777",
      phone2: "01272079613",
      fax: "",
      email: "speedlinecar1@gmail.com",
    },
    {
      name: "مطار القاهرة لخدمات الركاب",
      carType: "جميع المركات / All models",
      city: 'مطار القاهرة الدولى',
      address1: `مطار القاهرة الدولي – امام صالة وصول رقم 1 – المبنى الخدمي`,
      address2: "",
      director: "أ/ فهد الحلبي",
      phone1: "01222709475",
      phone2: "0222692461",
      fax: "19970",
      email: "info@cairoairport.travel",
    },
    {
      name: "مركز الأمين بالقطاميه",
      carType: "جميع المركات / All models",
      city: 'القاهرة الجديدة',
      address1: `أول طريق العين السخنة - خلف بنزينه وطينه - القطامية المنطقة الصناعية - قطعة 19 القاهرة الجديدة`,
      address2: "",
      director: "م/ محمد ناجح",
      phone1: "01005512336",
      phone2: "",
      fax: "01028722855",
      email: "mohamed.nageh@alamin-auto.com",
    },
    {
      name: "ناسيتا اوتوكير",
      carType: "جميع المركات / All models",
      city: 'وسط البلد',
      address1: `15 أ شارع 26 يوليو وسط البلد - بجوار جراند اوتيل`,
      address2: "",
      director: "كريم أبادير",
      phone1: "01011180407",
      phone2: "",
      fax: "19725",
      email: "admin-autocare@nacita.com",
    },
    {
      name: "سيلتك للاستشارات والخدمات المتكاملة",
      carType: "جميع المركات / All models",
      city: 'مصر الجديده',
      address1: `9 عبدلله دراز – ارض الجولف – مصر الجديدة – الدور التاسع`,
      address2: "",
      director: "احمد جمال",
      phone1: "0224158587",
      phone2: "0222913195",
      fax: "01158053713",
      email: "info@celltek.com.eg",
    },
    {
      name: "الاهلية الهندسية لقطع الغيار",
      carType: "جميع المركات / All models",
      city: 'القاهرة',
      address1: `33 شارع زايد – المنطقة الصناعية – العباسية - القاهرة`,
      address2: "",
      director: "وحيد شوقي زيتون",
      phone1: "01222231832",
      phone2: "",
      fax: "01222231832",
      email: "wahidnesco@gmail.com",
    },
    {
      name: "مودرن ماشينري للتجارة",
      carType: "جميع المركات / All models",
      city: 'مدينة نصر',
      address1: `88 ش مكرك عبيد – م نصر - القاهرة`,
      address2: "",
      director: "م/محمد يحيي",
      phone1: "01019077785",
      phone2: "",
      fax: "01019077785",
      email: "Mahmoud.shaheen@modern-machinery-eg.com",
    },
  ],
  otherGovs: [
    {
      name: "شركة الرزق للتجارة",
      carType: "جميع المركات/ تويوتا All models /  Toyota",
      city: 'الأسكندرية',
      address1: `طريق مصر الأسكندرية الصحراوى كم 25 قبلى – محافظة الأسكندرية`,
      address2: "",
      director: "م / سامح",
      phone1: "01065503998",
      phone2: "",
      fax: "",
      email: "ibrahim-rizk@elrizk-trading.com",
    },
    {
      name: "الأدهم لنظم الأمان",
      carType: "جميع المركات / All models",
      city: 'المنوفية',
      address1: `برج 3 - شقه 343 - ابراج بشائر الخير, شبين الكوم - المنوفية`,
      address2: "",
      director: "عميد/ وجدى عبد الحميد",
      phone1: "01100330010",
      phone2: "01100330020",
      fax: "",
      email: "ahmed.wagdy@aladham-eg.com",
    },
    {
      name: "مركز أسوان لخدمات السيارات",
      carType: "جميع المركات / All models",
      city: 'أسوان',
      address1: `محطة تموين الوقود – أمام موقف الأقاليم بأسوان – أمام الجامعى السيل- محافظة أسوان`,
      address2: "",
      director: "أ/ وليم فخرى سعيد",
      phone1: "01282812981",
      phone2: "01010391519",
      fax: "",
      email: "aswancars2@gmail.com",
    },
    {
      name: "الاسكندرية المتحدة - برو راض",
      carType: "جميع المركات / All models",
      city: 'الأسكندرية',
      address1: `179 طريق مطروح بجوار بنزينة أويل لبيا - الكيلو 16 الاسكندرية`,
      address2: "",
      director: "م/ مجدي البرلسي",
      phone1: "01111118618",
      phone2: "0233020286",
      fax: "",
      email: "broradco@gmail.com",
    },
  ],
  agents: [
    {
      name: "ابو غالى موتورز",
      carType: "جيب - دودج - كريسلير - سوبارو  Jeep - Dodge - Chrysler  subaru",
      city: ' - - ',
      address1: "ك 28 طريق اسماعيلية الصحراوى-العبور",
      address2: "زهراء المعادى-المنطقة الصناعية-قطعة 180",
      address3: "20ش تومنباى-سراى القبة",
      address4: "مدينة قباء-المنطقة الصناعية-جسر السويس",
      address5: "طريق القبانى-امام مرور الاوبرا-الاسكندرية",
      director: "",
      phone1: "46300000",
      phone2: "",
      fax: "19542",
      email: "ghetany_1@hotmail.com",
    },
    {
        name: "Toyota Egypt تويوتا ايجيبت",
        carType: "TOYOTA تويوتا",
        city: ' - - ',
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        director: "",
        phone1: "16550",
        phone2: "",
        fax: "",
        email: "",
      },
    {
        name: "الشركة الهندسية لخدمة السيارات SMG Engineering Automotive Co.",
        carType: "PORCHE بورش",
        city: ' - - ',
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        director: "",
        phone1: "19862",
        phone2: "",
        fax: "",
        email: "",
      },
    {
        name: "Diamond Motors دايموند موتورز",
        carType: "MITSUBISHI ميتسوبيشى",
        city: ' - - ',
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        director: "",
        phone1: "16606",
        phone2: "",
        fax: "",
        email: "",
      },
  ],
};
