import React from "react";
import "./MapWithPins.css"; // Import styles

import mapImg from "./map.jpg";
import pinImg from "./pin.png";

const MapWithPins = () => {
  // Array of pin positions (top and left percentages)
  const gpsPins = [
    { id: 1, top: "30%", left: "8%" }, // Pin 1
    { id: 2, top: "20%", left: "60%" }, // Pin 2
    { id: 3, top: "70%", left: "13%" }, // Pin 3
    { id: 4, top: "46%", left: "77%" }, // Pin 4
    { id: 5, top: "59%", left: "43%" }, // Pin 5
    { id: 6, top: "30%", left: "80%" }, // Pin 6
    { id: 7, top: "50%", left: "20%" }, // Pin 6
    { id: 8, top: "25%", left: "30%" }, // Pin 6
    { id: 9, top: "70%", left: "60%" }, // Pin 6
    { id: 10, top: "25%", left: "90%" }, // Pin 6
  ];

  return (
    <div className="map-container">
      {/* Map background */}
      <img src={mapImg} alt="Map" className="map-image" />
      {/* Render GPS Pins */}
      {gpsPins.map((pin) => (
        <div
          key={pin.id}
          className="gps-pin"
          style={{
            top: pin.top,
            left: pin.left,
          }}
        >
          <div className="pin-bounce">
            <img src={pinImg} alt="pin" class="pin-image" />
          </div>{" "}
          {/* Optional bounce animation */}
        </div>
      ))}
    </div>
  );
};

export default MapWithPins;
