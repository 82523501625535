import { Box, Grid, Slide } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./OurProductsStyle.css";
import etit_120 from "../../imgs/devices/ETIT-120.png";

import { Context } from "../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import DevicesDetails from "../devices/devicesDetails/DevicesDetails";
import { handleScrollFun } from "../../util/handleScrollFun";

function OurProducts() {
  const context = useContext(Context);
  const { t } = useTranslation();

  const elementRef1 = useRef(null);
  const [isVisible1, setIsVisible1] = useState(false);

  useEffect(() => {
    if (elementRef1.current) {
      handleScrollFun(elementRef1, setIsVisible1);
    }
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        p: 3,
        backgroundColor: "#2c3e50",
      }}
      dir={context.dir}
      ref={elementRef1}
    >
      <h1 className="products-header">{t("prod_header")}</h1>
      <Box sx={{ flexGrow: 1, color: "white" }}>
        <Grid sx={{ p: "20px 5px" }} container>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="product-img-container"
            sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}
          >
            <Slide
              direction="right"
              timeout={2000}
              in={isVisible1}
              container={elementRef1.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img src={etit_120} alt="dev" className="product-img" />
            </Slide>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Slide
              direction="up"
              timeout={2000}
              in={isVisible1}
              container={elementRef1.current}
              // mountOnEnter
              // unmountOnExit
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10% 0px",
                }}
              >
                <h1
                  className="products-subtitle"
                  variant="h2"
                  //   className="serv-img-header"
                >
                  ETIT-120
                </h1>
                <p className="products-paragraph">{t("prod_p")}</p>

                <DevicesDetails
                  t={t}
                  deviceData={{
                    name: "ETIT-120",
                    cat: "vehicle",
                    det1: t("prod_p"),
                    det2: "",
                    det3: "",
                  }}
                />
              </Box>
            </Slide>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="product-img-container"
            sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}
          >
            <Slide
              direction="right"
              timeout={2000}
              in={isVisible1}
              container={elementRef1.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img src={etit_120} alt="dev" className="product-img" />
            </Slide>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default OurProducts;
