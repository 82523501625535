import { Box, Grid } from "@mui/material";
import React from "react";

import "./ServicesDetailsStyle.css";
import cybersecurityImg from "../../imgs/services/cybersecurity.png";
// import vehicleTrackingImg from "../../imgs/services/gps.png";
// import personalTracking from "../../imgs/services/person.png";

import OfflinePinIcon from "@mui/icons-material/OfflinePin";
// import { HashLink } from "react-router-hash-link";

import { Context } from "../LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function CybersecurityServicesDetails() {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        p: 3,
        pt: 8,
      }}
    >
      {/* <h1 className="service-header">{t("cyber_security_services")}</h1> */}

      <Box sx={{ flexGrow: 1, color: "white" }}>
        {/****************** Cyber management system */}

        <Grid sx={{ p: "20px 5px" }} container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-det-container"
          >
            <img
              src={cybersecurityImg}
              alt="services"
              className="serv-det-img"
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px",
                padding: "0px",
              }}
            >
              {context.dir === "ltr" ? (
                <h1 className="serv-subtitle" id="cyber">
                  <span>{t("serv_cyber_t_bold")}</span>
                  {t("serv_cyber_t_normal")}
                </h1>
              ) : (
                <h1 className="serv-subtitle" id="cyber">
                  {t("serv_cyber_t_normal")}
                  <span>{t("serv_cyber_t_bold")}</span>
                </h1>
              )}

              <p className="serv-paragraph">{t("serv_cyber_p")}</p>
              <ul className="serv-list-item" dir={context.dir}>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_cs_li_1")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_cs_li_2")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_cs_li_3")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_cs_li_4")}
                </li>
                <li>
                  <OfflinePinIcon className="serv-list-item-icon" />
                  {t("serv_det_cs_li_5")}
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CybersecurityServicesDetails;
