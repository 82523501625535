import ChatBot from "react-chatbotify";
// import { useChatHistory } from "react-chatbotify";
import { useFirstInteraction } from "react-chatbotify";

import Cookies from "js-cookie";

import GigaBot_avatar from "../../imgs/sensor.png";
import { t } from "i18next";

const GigaBot = () => {
  // const { showChatHistory } = useChatHistory();
  const { hasFlowStarted } = useFirstInteraction();

  console.log("hasFlowStarted : ", hasFlowStarted);
  const helpOptions = ["عرض خدماتنا", "دعم فنى"];
  const supportOptions = ["لا استطيع تتبع سيارتي", "بطئ في الخدمة"];
  const servicesOptions = [
    "خدمـات التتبـع",
    "خدمـات تكامل الأنظمـة",
    "تطويــر البرمجيات",
    "خدمـات شبكـات الحاســب",
    "الخدمــات السحابيــة",
    "خدمـات الأمـن السيبرانى",
  ];
  const seenBefore = Cookies.get("guest_name");
  const flow = {
    start: {
      message: () =>
        seenBefore
          ? `مرحباً بك مرة أخرى ${seenBefore}`
          : "أهلا بيك، أنا اسمى جيجا بوت مساعدك الشخصى من الشركة المصرية لخدمات التتبع وتكنولوجيا المعلومات",
      transition: { duration: 1000 },
      path: () => (seenBefore ? "offering_help" : "ask_name"),
    },
    ask_name: {
      message: "انت اسمك ايه؟",
      // function: (params) => setForm({...form, age: params.userInput}),
      function: (params) =>
        Cookies.set("guest_name", params.userInput, { expires: 1 }),
      path: "say_hi",
    },
    say_hi: {
      message: (params) => `اهلا بيك يا ${params.userInput}`,
      // function: (params) => getHistoryMessages setForm({...form, age: params.userInput}),
      transition: { duration: 1000 },
      path: "offering_help",
    },
    offering_help: {
      message: (params) => `اختار اقدر اساعدك ازاي؟ `,
      // function: (params) => setForm({...form, age: params.userInput}),
      transition: { duration: 1000 },
      path: "show_help_options",
    },
    show_help_options: {
      options: helpOptions,
      path: "process_help_options",
    },
    support_options: {
      message: "رجاءً وضح نوع المشكلة؟",
      options: supportOptions,
      path: "process_support_options",
    },
    services_options: {
      message:
        "توفر الشركة المصرية لخدمات التتبع وتكنولوجيا المعلومات مجموعه كبيره من الخدمات التى يمكنك الإستفادة منها، رجاءً إختر خدمة",
      options: servicesOptions,
      path: "process_services_options",
    },
    prompt_again: {
      message: "لسه محتاج لمساعده؟",
      options: helpOptions,
      path: "process_help_options",
    },
    call_support: {
      message: "رجاءً اتصل على 19382",
      transition: { duration: 1000 },
      // options: helpOptions,
      path: "prompt_again",
    },
    unknown_input: {
      message: "عذرًا، لا أفهم رسالتك 😢! رجاءً أختار من القائمة التالية:",
      options: helpOptions,
      path: "process_help_options",
    },
    process_help_options: {
      transition: { duration: 0 },
      chatDisabled: false,
      path: async (params) => {
        let link = "";
        switch (params.userInput) {
          case "عرض خدماتنا":
            return "services_options";
          // link = "/services-det";
          // break;
          case "دعم فنى":
            return "support_options";
          default:
            // return "unknown_input";
            break;
        }

        await params.injectMessage("رجاءً انتظر...");
        setTimeout(() => {
          window.open(link, "_self");
        }, 1000);
        return "repeat";
      },
    },
    process_support_options: {
      transition: { duration: 0 },
      chatDisabled: true,
      path: async (params) => {
        switch (params.userInput) {
          case "لا استطيع تتبع سيارتي":
            return "call_support";
          case "بطئ في الخدمة":
            return "call_support";
          default:
            return "unknown_input";
        }
      },
    },

    process_services_options: {
      transition: { duration: 0 },
      chatDisabled: true,
      path: async (params) => {
        let link = "";
        switch (params.userInput) {
          case "خدمـات التتبـع":
            link = "/tracking-services-det";
            break;
          case "خدمـات تكامل الأنظمـة":
            link = "/system-integration-services-det";
            break;
          case "تطويــر البرمجيات":
            link = "/software-development-services-det";
            break;
          case "خدمـات شبكـات الحاســب":
            link = "/network-services-det";
            break;
          case "الخدمــات السحابيــة":
            link = "/cloud-services-det";
            break;
          case "خدمـات الأمـن السيبرانى":
            link = "/cyber-security-services-det";
            break;
          default:
            return "unknown_input";
        }

        await params.injectMessage("رجاءً انتظر...");
        setTimeout(() => {
          window.open(link, "_self");
        }, 1000);
        return "repeat";
      },
    },

    repeat: {
      transition: { duration: 3000 },
      path: "prompt_again",
    },
  };
  const settings = {
    general: {
      primaryColor: "#2c3e50",
      secondaryColor: "#f39c12",
      fontFamily: "Arial, sans-serif",
      showFooter: true,
      desktopEnabled: true,
      mobileEnabled: true,
    },

    chatHistory: {
      storageKey: "gigabot_etit_chatbot",
      // disabled: false,
      // maxEntries: 30,
      // // viewChatHistoryButtonText: "Load Chat History ⟳",
      // // chatHistoryLineBreakText: "----- Previous Chat History -----",
      // autoLoad: true,
    },

    chatButton: {
      icon: GigaBot_avatar,
    },
    tooltip: {
      // mode: "",
      text: t("gigaBot_tooltip"),
    },
    header: {
      title: (
        <div
          style={{
            // cursor: "pointer",
            margin: 0,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Giga Bot
        </div>
      ),
      showAvatar: true,
    },
    fileAttachment: {
      disabled: true,
    },
    emoji: {
      disabled: true,
    },
    footer: {
      text: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: 3,
          }}
        >
          <span key={0}>Powered By </span>

          <span key={1} style={{ color: "#f39c12", fontWeight: "bold" }}>
            ETIT
          </span>
        </div>
      ),
      // buttons: [Button.FILE_ATTACHMENT_BUTTON, Button.EMOJI_PICKER_BUTTON]
    },
    userBubble: {
      showAvatar: true,
      // avatar: userAvatar,
    },
    botBubble: {
      showAvatar: true,
      // avatar: botAvatar,
    },
    // chatHistory: { storageKey: "gigabot_etit_chatbot" },
  };
  const styles = {
    chatButtonStyle: {
      width: "4rem",
      height: "4rem",
      bottom: "5rem",
    },
    chatIconStyle: { width: "4rem", height: "4rem" },
    tooltipStyle: {
      fontSize: "15px",
      minWidth: "100px",
      //   height: "50px",
      zIndex: "1",
      // position: "absolute",
      bottom: "5rem",
      right: "6rem",
    },
  };

  return <ChatBot dir="rtl" settings={settings} flow={flow} styles={styles} />;
};

export default GigaBot;
