import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { notify } from "../../theme/notification";

import Cookies from "js-cookie";

import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

function PublicNotification() {
  const [notificationClose, setNotificationClose] = useState(false);

  const today = new Date();
  const dateStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
  //   console.log("dateStr : ", dateStr);
  //   const date = new Date(dateStr);
  //   console.log("date : ", date);

  //   console.log(" notify.startDate : ", notify.startDate);
  //   console.log(" notify.endDate : ", notify.endDate);

  //   console.log(" Date.parse(date) : ", Date.parse(date));
  //   console.log(" Date.parse(notify.endDate) : ", Date.parse(notify.endDate));
  //   console.log(" notify.message : ", notify.message);

  // Date.parse(date) >= Date.parse(notify.startDate)
  // Date.parse(date) <= Date.parse(notify.endDate)

  const closePubNotification = Cookies.get("closePubNotification");

  useEffect(() => {
    if (closePubNotification) {
      setNotificationClose(closePubNotification);
    }
  }, [closePubNotification]);

  const handleNotificationBar = () => {
    setNotificationClose(true);
    Cookies.set("closePubNotification", true, { expires: 1 });
  };

  return (
    /* ************** notification bar ************** */

    !notificationClose && (
      // Date.parse(date) >= Date.parse(notify.startDate) &&
      // Date.parse(date) <= Date.parse(notify.endDate) &&
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // marginTop: "90px",
          p: "10px 5px",
          bgcolor: "#2c3e50b8",
          boxShadow: { xs: "none", sm: "0px 2px 3px #0000009d" },
        }}
      >
        {/* ********* message ********* */}
        <Box sx={{ flex: 11 }}>
          <h6 style={{ color: "white" }}>{notify.message}</h6>
        </Box>

        {/* ********* close message ********* */}
        <Box sx={{ flex: 1, color: "white", ":hover": { color: "red" } }}>
          <IconButton
            color="inherit"
            aria-label="close notification"
            edge="start"
            onClick={handleNotificationBar}
          >
            <CancelPresentationIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    )
  );
}

export default PublicNotification;
