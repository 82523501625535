import React from "react";

import HomeSlider from "../slider/Slider";
import Services from "../services/Services";
// import About from "../about/About";
import OurDevices from "../devices/OurDevices";
import OurProducts from "../products/OurProducts";
import Sensors from "../sensors/Sensors";
import ContactUs from "../contact/ContactUs";
import Partners from "../partners/Partners";

import CookieConsent from "react-cookie-consent";
import { t } from "i18next";
import StayUpdated from "../stayUpdated/StayUpdated";
import WhyUs from "../whyUs/WhyUs";
import DownloadApps from "../apps/DownloadApps";
import Applications from "../apps/Applications";
import MapWithPins from "../mapAnimation/MapWithPins";

function Home() {
  return (
    <div>
      <HomeSlider />

      <WhyUs />
      <Services />
      <OurDevices />
      <OurProducts />
      <Sensors />
      <Applications />
      <Partners />
      <ContactUs />
      <StayUpdated />
    </div>
  );
}

export default Home;
