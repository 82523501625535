import React, { useContext, useEffect, useState } from "react";
import { createAppointment } from "../../../backendServices/appointmentService";
import { getAllSlots } from "../../../backendServices/timeSlotService";
import { Box, Grid } from "@mui/material";
import Cookies from "js-cookie";

import "./bookStyle.css";
import Notify from "../../Notify";
import BookDet from "./BookDetQrCode";

import { t } from "i18next";
import { Context } from "../../LocalWrapper";

const AppointmentForm = ({ token }) => {
  const context = useContext(Context);

  const [appointmentId, setAppointmentId] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [bookDetString, setBookDetString] = useState("");

  const [formData, setFormData] = useState({
    service: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    const bookDetailsString = Cookies.get("bookDetailsString");
    if (bookDetailsString) {
      setBookDetString(bookDetailsString);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createAppointment(token, { ...formData });
      const bookDetails = `BookingNo: ${response.data.appointmentId}, \nName: ${context.user.name}, \nDate: ${formData.date}, \nTime: ${formData.time}`;
      Cookies.set("bookDetailsString", bookDetails, { expires: 1 / 24 }); //cookies will expires after one hour

      Cookies.set(
        "bookDetailsObject",
        JSON.stringify({
          ...formData,
          appointmentId: response.data.appointmentId,
          userName: context.user.name,
        }),
        { expires: 1 / 24 }
      ); //cookies will expires after one hour

      setBookDetString(bookDetails);
      setAppointmentId(response.data.appointmentId);
      Notify(1, "Appointment booked successfully");
    } catch (error) {
      Notify(0, `${error.message}, Please try again.`);
      console.error("Error booking appointment:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const fetchTimeSlots = async () => {
    try {
      const response = await getAllSlots(token);
      console.log("fetchTimeSlots => response.data: ", response.data);
      setTimeSlots(response.data.timeSlots);
    } catch (error) {
      console.error("Failed to fetch time slots", error);
    }
  };

  useEffect(() => {
    fetchTimeSlots();
  }, [token]);

  return (
    <Box dir={context.dir} className="b_section-container">
      <Grid container spacing={2} sx={{ pt: "30px" }}>
        <Grid item xs={1} sm={3} md={3} lg={4}></Grid>
        <Grid item xs={10} sm={6} md={6} lg={4}>
          {bookDetString ? (
            <BookDet bookDetString={bookDetString} />
          ) : (
            <div className="book-det-container container p-4 shadow-sm bg-white rounded">
              <h3 className="mb-4 text-center">{t("book_header")}</h3>
              <form onSubmit={handleSubmit} className="book-form">
                <div className="form-group mb-3">
                  <label htmlFor="date" className="form-label">
                    {t("date")}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="time" className="form-label">
                    {t("time")}
                  </label>

                  <select
                    id="time"
                    name="time"
                    className="form-control"
                    defaultValue={timeSlots[0]}
                    onChange={handleChange}
                    required
                  >
                    {timeSlots.map((slot) => (
                      <option key={slot.id} value={slot.time}>
                        {slot.time}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="service" className="form-label">
                    {t("service_type")}
                  </label>
                  <select
                    className="form-select"
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a service</option>
                    <option value="Fleet Management System">
                      Fleet Management System
                    </option>
                    <option value="Vehicle Tracking">Vehicle Tracking</option>
                    <option value="Personal Tracking">Personal Tracking</option>
                  </select>
                </div>

                <button type="submit" className="btn btn-primary w-100">
                  {t("book_btn")}
                </button>
              </form>
            </div>
          )}
        </Grid>
        <Grid item xs={1} sm={3} md={3} lg={4}></Grid>
      </Grid>
      {/* {appointmentId && (
        <UploadDocuments appointmentId={appointmentId} token={token} />
      )} */}
    </Box>
  );
};

export default AppointmentForm;

{
  /* {appointmentId && (
              <UploadDocuments appointmentId={appointmentId} token={token} />
            )} */
}
