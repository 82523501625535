import { QRCodeSVG } from "qrcode.react";
import Cookies from "js-cookie";
import { Divider } from "@mui/material";
import etit_full_logo from "../../../imgs/etit_full_logo.png";

const BookDetQrCode = ({ bookDetString }) => {
  const bookDetails = JSON.parse(Cookies.get("bookDetailsObject"));
  console.log("bookDetails: ", bookDetails);
  return (
    <div
      dir="ltr"
      className="appointment-det-container container p-4 shadow-sm bg-white rounded"
    >
      <img src={etit_full_logo} className="mb-4 " alt="logo" />
      <Divider className="divider" variant="middle" />
      <h3 className="mb-4 text-center">Appointment Details</h3>
      <QRCodeSVG value={bookDetString} />
      <div className="appointment-items">
        <h6>
          <span>No: </span>
          {bookDetails.appointmentId}
        </h6>
        <h6>
          <span>Name: </span>
          {bookDetails.userName}
        </h6>
        <h6>
          <span>Service: </span>
          {bookDetails.service}
        </h6>
        <h6>
          <span>Date: </span>
          {bookDetails.date}
        </h6>
        <h6>
          <span>Time: </span>
          {bookDetails.time}
        </h6>
      </div>
      <Divider className="divider" variant="middle" />
      <span>Please take screenshot for the QR code</span>
    </div>
  );
};

export default BookDetQrCode;
