import React from "react";
import { TextField } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import { withStyles } from "@mui/styles";

// const style = ({ theme }) => ({
//   input: {
//     mb: 1,
//   },
// });
const styles = {
  root: {
    // background: "black",
    input: { color: "white" },
    border: 0,
    mb: 1,
  },
};

// function Input(props) {
const Input = React.forwardRef((props, ref) => {
  const {
    variant,
    name,
    label,
    value,
    error = null,
    onChange,
    classes,
    ...other
  } = props;

  return (
    <TextField
      variant={variant}
      label={label}
      name={name}
      value={value || ""}
      onChange={onChange}
      // focused
      color="primary"
      ref={ref}
      // className={classes.root}
      // InputProps={{
      //   className: classes.input,
      // }}
      inputProps={{ style: { color: "#000" } }}
      InputLabelProps={{
        style: { color: "#ffffff" },
      }}
      sx={{
        // borderRadius: "3px",
        margin: "10px 0px",
        backgroundColor: "#ffffff55",
        border: "1px solid #34495e",
        borderRadius: "3px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#34495e",
          borderWidth: "0px",
          // backgroundColor: "#ffffff55",
        },
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
});

export default Input;
// export default withStyles(styles)(Input);
