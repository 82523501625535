import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { Context } from "../../LocalWrapper";

import etitLogo from "../../../imgs/etit_logo.png";
import quicklinkLogo from "../../../imgs/devices/quicklinkLogo.png";
import egyFlag from "../../../imgs/Egypt-Flag2.png";

import stolenCarImg from "../../../imgs/devices/stolen-car.png";
import fleetManageImg from "../../../imgs/devices/fleet-manag.png";

import assetTrackingImg from "../../../imgs/devices/Asset-Tracking.png";
import personalSecurityImg from "../../../imgs/devices/Personal-Security.png";

import { Box, Grid } from "@mui/material";

import DevicesSpecs from "./DevicesSpecs";

export default function DevicesDetails({ t, deviceData }) {
  const context = useContext(Context);
  const [open, setOpen] = React.useState(false);

  // const name = deviceData.name;
  // const det1 = deviceData.det1;
  // const det2 = deviceData.det2;
  // const det3 = deviceData.det3;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          bgcolor: "#FF8C05",
          fontSize: "15px",
          fontWeight: "600",
          padding: "10px",
          margin: "15px",
          "&:hover": {
            backgroundColor: "#d35400",
          },
        }}
        onClick={handleClickOpen}
        dir={context.dir}
      >
        {t("device_specs")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { backgroundColor: "#fff" },
        }}
        scroll="paper"
        maxWidth="lg"
        dir={context.dir}
      >
        <DialogTitle
          sx={{
            color: "white",
            backgroundColor: "#1f1f1f",
          }}
          id="alert-dialog-title"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img src={etitLogo} alt="logo" style={{ width: "10vh" }} />
            <h2 style={{ color: "white" }}> {deviceData.name} </h2>
            {deviceData.name === "ETIT-120" ? (
              <img src={egyFlag} alt="img" style={{ width: "12vh" }} />
            ) : (
              <img
                src={quicklinkLogo}
                alt="img"
                style={{ width: "12vh", height: "6vh" }}
              />
            )}
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid container sx={{ paddingTop: "0px" }}>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Box className="det-main-container">
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "100%", sm: "70%" },
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <DialogContentText
                    sx={{ color: "black", margin: "10px" }}
                    id="alert-dialog-description"
                  >
                    {deviceData.det1}
                  </DialogContentText>
                  <DialogContentText
                    sx={{ color: "black", margin: "10px" }}
                    id="alert-dialog-description"
                  >
                    {deviceData.det2}
                  </DialogContentText>
                  <DialogContentText
                    sx={{ color: "black", margin: "10px" }}
                    id="alert-dialog-description"
                  >
                    {deviceData.det3}
                  </DialogContentText>
                </Box>
                <Box sx={{ flex: 1 }} className="det-img-container">
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {deviceData.cat === "personal" ? (
                        <img src={assetTrackingImg} alt="img" />
                      ) : (
                        <img src={stolenCarImg} alt="img" />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {deviceData.cat === "personal" ? (
                        <img src={personalSecurityImg} alt="img" />
                      ) : (
                        <img src={fleetManageImg} alt="img" />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <DevicesSpecs deviceName={deviceData.name} />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("close_btn")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
