// import dir1 from "../../../imgs/about/Directors//dir1.png";

/* ************  high managment  ************** */
export const HighManagment = [
  {
    name: "لواء / سمير محمد فتحى",
    title: " رئيس مجلس الإداره و العضو المنتدب",
    lat_name: "Adml / Samir Mohamed Fathy",
    lat_title: " Chairman and Managing Director",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "وكيل / يحى السيد احمد",
    title: " نائب رئيس مجلس الإداره",
    lat_name: "Gen / Yahya Elsayed Ahmed",
    lat_title: " Vice Chairman ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "عميد / ياسر محمد مدحت ",
    title: " المدير التنفيذي للقطاع الفني والتجاري",
    lat_name: "Dr / Yasser Medhat Mahgoub",
    lat_title: " Executive Director of the Technical and Commercial Sector ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "وكيل / هشام محمد الخطيب",
    title: "  المدير التنفيذي للقطاع المالي والاداري",
    lat_name: "Gen / Hisham Mohammed Al-Khatib ",
    lat_title: " Executive Director of the Financial and Administrative Sector ",
    img: require("../../../imgs/etit_logo.png"),
  },
];

/* ************  technical Managment  ************** */
export const TechManagment = [
  {
    name: "مهندس / وائل الدويري",
    title: "مدير إدارة العمليات",
    lat_name: "ENG / Wael Al-Duwairi ",
    lat_title: " Director of Operations Department ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "مهندس / هيثم فاروق",
    title: "مدير إدارة التركيبات والصيانه",
    lat_name: "ENG / Haitham Farouk ",
    lat_title: " Director of Installations and Maintenance Department ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "مهندس / محمود شديد",
    title: "مدير إدارة البحوث والتطوير",
    lat_name: "ENG / Mahmoud Shadid",
    lat_title: " Director of Research and Development Department ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "مهندس / محمد فهمى",
    title: "مدير إدارة التسويق والمبيعات",
    lat_name: "ENG / Mohamed Fahmy ",
    lat_title: " Director of Marketing and Sales Department ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "مهندس / ياسر عز",
    title: "مدير إدارة نظم المعلومات",
    lat_name: "ENG / Yasser Ezz ",
    lat_title: " Director of Information Systems Department ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "مهندس / خالد",
    title: "رئيس قسم التخطيط",
    lat_name: "ENG / khalid",
    lat_title: " Head of the planning department ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "مهندس / عماد عابدين",
    title: "رئيس قسم الإنتاج والتجميع",
    lat_name: "ENG / Emad Abdeen ",
    lat_title: " Head of production and assembly department ",
    img: require("../../../imgs/etit_logo.png"),
  },
];

/* ************  financial Managment  ************** */

export const FinancialManagment = [
  {
    name: "لواء / أمجد عياد",
    title: "مدير إدارة الشؤون الإداريه",
    lat_name: "Gen / Amjad Ayad ",
    lat_title: " Director of Administrative Affairs Department ",
    img: require("../../../imgs/etit_logo.png"),
  },
  {
    name: "مهندس / شريف بدور",
    title: "مدير الإدارة المالية",
    lat_name: "ENG / Sherif Bador ",
    lat_title: " Director of the Financial Department ",
    img: require("../../../imgs/etit_logo.png"),
  },
];
