import { verifyToken } from "./authService";
import Cookies from "js-cookie";

const { useState, useEffect } = require("react");
const { Outlet, Navigate } = require("react-router-dom");

// ****************************** used for protected routes ******************************

export const verifyTokenWithBackend = async (token) => {
  try {
    if (token) {
      const { data } = await verifyToken(token);

      console.log("Token verification data is:", data);
      return data.isValid; // Backend should return { isValid: true } if the token is valid
    }
  } catch (error) {
    // console.error("Token verification failed:", error);
    return false;
  }
};

const RequireAuth = () => {
  // autenticated state
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const token = Cookies.get("authToken");

  useEffect(() => {
    if (!token) {
      setIsAuthenticated(false);
      return;
    }

    const checkAuth = async () => {
      console.log("isValid token: ", token);
      const isValid = await verifyTokenWithBackend(token);
      console.log("isValid: ", isValid);
      setIsAuthenticated(isValid);
    };

    checkAuth();
  }, [token]);

  console.log("token: ", token);
  console.log("isAuthenticated: ", isAuthenticated);

  if (isAuthenticated === null) return <div>Loading...</div>;
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default RequireAuth;
