// import dir1 from "../../../imgs/about/Directors/dir1.png";

/* ************  Best Emps data  ************** */
export const BestEmps = [
  {
    name: "مصطفى حبشى",
    title: " أستاذ",
    lat_name: " Mostafa Habashi ",
    lat_title: "Mr",
    img: require("../../../imgs/about/emp/habashi.png"),
  },
  {
    name: " صابر سيد",
    title: " مهندس",
    lat_name: "Saber Sayed",
    lat_title: " ENG",
    img: require("../../../imgs/about/emp/saber.png"),
  },

  {
    name: "خليفة",
    title: "أستاذ",
    lat_name: "Khalifa ",
    lat_title: " Mr ",
    img: require("../../../imgs/about/emp/khalifa.png"),
  },
  {
    name: "إسلام بسيونى ",
    title: " مهندس",
    lat_name: "Eslam Basiony ",
    lat_title: " ENG ",
    img: require("../../../imgs/about/emp/basiony.png"),
  },
];
