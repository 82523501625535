import { Box, Grid } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import React from "react";
import "./SensorsStyle.css";
import sensorImg from "../../imgs/sensor.png";

import { Context } from "../../components/LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

function Sensors() {
  const context = useContext(Context);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "70vh",
        p: 2,
        pt: 5,

        // backgroundColor:'red'
      }}
    >
      <h1 className="sensors-header1">{t("sen_header1")}</h1>
      {context.dir === "ltr" && <h1 className="sensors-header2"> & </h1>}
      <h1 className="sensors-header3">{t("sen_header2")}</h1>

      <Box sx={{ flexGrow: 1, color: "000000" }}>
        {/****************** fleet management system */}

        <Grid sx={{ p: "20px 5px" }} container>
          <Grid
            item
            container
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="sensors-left"
          >
            <h1
              data-tooltip-id="panic-button"
              data-tooltip-content="Used for emergency situations"
            >
              <Tooltip
                style={{ backgroundColor: "#FF8C05" }}
                id="panic-button"
              />

              {t("sen_p1")}
              <span>
                <AdjustIcon />
              </span>
            </h1>

            <h1
              style={{ paddingRight: "10%" }}
              data-tooltip-id="cut-engine"
              data-tooltip-content="Used in case of theft"
            >
              <Tooltip style={{ backgroundColor: "#FF8C05" }} id="cut-engine" />

              {t("sen_p2")}
              <span>
                <AdjustIcon />
              </span>
            </h1>
            <h1
              data-tooltip-id="door"
              data-tooltip-content="Check car door status"
            >
              <Tooltip style={{ backgroundColor: "#FF8C05" }} id="door" />

              {t("sen_p3")}
              <span>
                <AdjustIcon />
              </span>
            </h1>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} className="sensors-img">
            <img src={sensorImg} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="sensors-right"
          >
            <h1
              data-tooltip-id="fuel"
              data-tooltip-content="Check fuel tank status "
            >
              <Tooltip style={{ backgroundColor: "#FF8C05" }} id="fuel" />
              <span>
                <AdjustIcon />
              </span>
              {t("sen_p4")}
            </h1>
            <h1
              style={{ paddingLeft: "10%" }}
              data-tooltip-id="temp"
              data-tooltip-content="Keep track of the car's temperature "
            >
              <Tooltip style={{ backgroundColor: "#FF8C05" }} id="temp" />
              <span>
                <AdjustIcon />
              </span>
              {t("sen_p5")}
            </h1>
            <h1
              data-tooltip-id="ibtn"
              data-tooltip-content="Verify the identity of the driver "
            >
              <Tooltip style={{ backgroundColor: "#FF8C05" }} id="ibtn" />
              <span>
                <AdjustIcon />
              </span>
              {t("sen_p6")}
            </h1>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Sensors;
