import React from "react";
import { Box, Button, Grid } from "@mui/material";

import "./DownloadPageStyle.css";
import DownloadApps from "./DownloadApps";
import { useTranslation } from "react-i18next";

function Applications() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        width: "100%",
        minHeight: "30vh",
        // p: 3,
        pt: 15,
      }}
    >
      <Grid
        container
        direction="column"
        sx={{
          width: "100%",
          //   justifyContent: "center",
          //   alignItems: "start",
        }}
      >
        <Grid item className="header">
          <h1>{t("fms_page_h")}</h1>
          <p>{t("fms_page_p")}</p>
          <a
            href="https://etit-fms.etit-eg.com/"
            class="fms-login-btn rounded-pill"
            target="_blank"
            rel="noreferrer"
          >
            {t("fms_page_btn")}
          </a>
        </Grid>
        <DownloadApps />
      </Grid>
    </Box>
  );
}

export default Applications;
