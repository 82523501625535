import React, { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { verifyToken } from "../backendServices/authService";
import { loginApi } from "../backendServices/authService";
import Notify from "./Notify";

export const Context = createContext();

const languages = [
  {
    code: "EN",
    name: "English",
    country_code: "gb",
    dir: "ltr",
  },
  {
    code: "AR",
    name: "العربية",
    country_code: "eg",
    dir: "rtl",
  },
];

export const LocalWrapper = (props) => {
  const currentLangCode = Cookies.get("i18next") || "EN";
  const currentLang = languages.find((l) => l.code === currentLangCode);

  const [user, setUser] = useState(null);

  // Function to handle login
  const login = async (email, password) => {
    // console.log("login locawrapper: ");
    try {
      const response = await loginApi(email, password);

      const data = response.data;
      console.log("login response data: ", data);
      // Set user data in the context
      setUser({
        id: data.userId,
        name: data.name,
        email: data.email,
      });
      // Store token in Cookies
      Cookies.set("authToken", data.token, { expires: 1 });
      Cookies.set("user", user, { expires: 1 });
      Notify(1, "Login Successfully.");
      return "ok";
    } catch (error) {
      Notify(0, error?.response?.data?.message);
      console.error("Login error:", error);
    }
  };

  // Function to handle logout
  const logout = () => {
    setUser(null);
    Cookies.remove("authToken"); // Clear token
    Cookies.remove("user"); // Clear token
    Cookies.remove("bookDetails"); // Clear token
    window.location.reload();
  };

  // Check for user data on initial render
  useEffect(() => {
    const token = Cookies.get("authToken");
    if (token) {
      // Fetch user info from backend using the token
      verifyToken(token)
        .then((response) => {
          const user = response.data.user;
          setUser({ id: user.userId, name: user.name, email: user.email });
          // console.log("setUser: ", user);
        })
        .catch((err) => {
          console.log("catch err: ", err);
          logout();
        });
    }
    console.log("user : ", user);
  }, []);

  return (
    <Context.Provider
      value={{
        currentLang,
        languages,
        dir: currentLang.dir,
        user,
        login,
        logout,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

// export default LocalWrapper;

// Custom hook for accessing AuthContext
export const useAuth = () => useContext(Context);
