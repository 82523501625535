import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAppointments } from "../../backendServices/appointmentService";
import { Box } from "@mui/material";

const Dashboard = ({ token }) => {
  // const { user, login, logout } = useAuth();

  const [appointments, setAppointments] = useState([]);
  // const [editAppointment, setEditAppointment] = useState(null);

  useEffect(() => {
    fetchAppointments();
  }, [token]);

  const fetchAppointments = async () => {
    // window.location.reload();
    try {
      const response = await getAppointments(token);
      console.log("fetchAppointments => response.data: ", response.data);
      setAppointments(response.data.appointments);
    } catch (error) {
      console.error("Failed to fetch appointments", error);
    }
  };

  // const updateAppointment = async (id) => {
  //   try {
  //     await axios.put(
  //       `http://localhost:5000/appointments/${id}`,
  //       editAppointment,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     fetchAppointments();
  //     setEditAppointment(null);
  //     alert("Appointment updated successfully!");
  //   } catch (error) {
  //     console.error("Failed to update appointment", error);
  //   }
  // };

  // const deleteAppointment = async (id) => {
  //   try {
  //     await axios.delete(`http://localhost:5000/appointments/${id}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     setAppointments(appointments.filter((app) => app.id !== id));
  //     alert("Appointment canceled.");
  //   } catch (error) {
  //     console.error("Failed to cancel appointment", error);
  //   }
  // };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        p: 3,
        pt: 12,
      }}
    >
      <div>
        <h2>Your Appointments</h2>
        <div>
          {/* <h2>Appointments</h2> */}
          <div className="row">
            {appointments.map((appointment) => (
              <div key={appointment.id} className="col-md-4 mb-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{appointment.service}</h5>
                    <p className="card-text">
                      Date: {appointment.date} <br />
                      Time: {appointment.time} <br />
                      {/* User: {appointment.userId} */}
                    </p>
                    <button className="btn btn-danger">
                      Cancel Appointment
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* {editAppointment && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateAppointment(editAppointment.id);
          }}
        >
          <h3>Edit Appointment</h3>
          <input
            type="text"
            value={editAppointment.service}
            onChange={(e) =>
              setEditAppointment({
                ...editAppointment,
                service: e.target.value,
              })
            }
            required
          />
          <input
            type="date"
            value={editAppointment.date}
            onChange={(e) =>
              setEditAppointment({ ...editAppointment, date: e.target.value })
            }
            required
          />
          <input
            type="time"
            value={editAppointment.time}
            onChange={(e) =>
              setEditAppointment({ ...editAppointment, time: e.target.value })
            }
            required
          />
          <button type="submit">Save Changes</button>
        </form>
      )} */}
      </div>
    </Box>
  );
};

export default Dashboard;
