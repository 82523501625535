import React from "react";

function GoogleMap() {
  return (
    <div
      style={{
        height: "70vh",
        width: "100%",
        border: "1px solid var(--primary)",
      }}
    >
      <div class="position-relative h-100">
        <iframe
          title="ETIT Location"
          class="position-relative w-100 h-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3452.6062187622256!2d31.024980000000006!3d30.07682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14585b9b21ab0517%3A0xd5113a3855bf5da6!2sETIT-EG!5e0!3m2!1sen!2seg!4v1672734303390!5m2!1sen!2seg"
          frameborder="0"
          style={{ border: "0" }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  );
}
export default GoogleMap;
