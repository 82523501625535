import React from "react";
import { Box } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./SliderStyle.css";

// import slider0 from "../../imgs/slider/fleet.jpg"; //landing slide
import slider0 from "../../imgs/slider/etit_location_vid.mp4"; //landing slide
import slider00 from "../../imgs/slider/GPSCAR.jpg"; //landing slide

// import slider1 from "../../imgs/slider/slider1.png"; //landing slide
// import slider2 from "../../imgs/slider/slider2.png"; // etit building
// import slider6 from "../../imgs/slider/gps.jpg"; // gbs
// import slider3 from "../../imgs/slider/slider3.jpeg"; //data center
// import slider4 from "../../imgs/slider/slider4.jpeg"; //fleet
// import slider5 from "../../imgs/slider/slider5.png"; //call center
import { t } from "i18next";
import MapWithPins from "../mapAnimation/MapWithPins";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        // background: "green",
        zIndex: "1",
        right: "10px",
        width: "50px",
        height: "70%",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        // background: "green",
        zIndex: "1",
        left: "10px",
        width: "50px",
        height: "70%",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    />
  );
}

function HomeSlider() {
  const settings = {
    pauseOnHover: false,
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <Box className="dir-class">
      <Slider {...settings}>
        <div className="slide">
          {/* <img className="slide-image" alt="slider" src={slider0} />{" "} */}
          {/* <video autoPlay muted loop className="slide-image">
            <source src={slider0} type="video/mp4" />
          </video> */}
          <MapWithPins />
          <div className="slide-det-container">
            <h1 className="slide-header">
              {/* <span>{t("nav_compTitle")}</span> */}
              {t("nav_compTitle")}
            </h1>
            <h1 className="slide-subtitle" style={{ color: "var(--primary)" }}>
              {t("nav_slogan")}
            </h1>
            <div className="slide-btn-group">
              <a href="/book" className="slide-btn rounded-pill">
                {t("reserve")}
              </a>
              <a href="/about" className="slide-about-btn rounded-pill">
                {t("nav_about")}
              </a>
            </div>
          </div>
        </div>

        <div className="slide">
          <img
            className="slide-image sImgAnimate"
            alt="slider"
            src={slider00}
          />
          <div className="slide-det-container">
            <h1 className="slide-header">
              {t("vision_phrase1")}
              {/* <span> are </span>... */}
            </h1>
            <h1 className="slide-subtitle">
              <span>{t("vision_phrase2")}</span>
            </h1>
            <div className="slide-btn-group">
              <a href="/book" className="slide-btn rounded-pill">
                {t("reserve")}
              </a>
              <a href="/about" className="slide-about-btn rounded-pill">
                {t("nav_about")}
              </a>
            </div>
          </div>
        </div>

        <div className="slide">
          <video autoPlay muted loop className="slide-image">
            <source src={slider0} type="video/mp4" />
          </video>
          <div className="slide-det-container">
            <h1 className="slide-header">
              {t("slider_need_help.0")}
              <span>{t("slider_need_help.1")}</span>
            </h1>
            <h1 className="slide-subtitle">
              {t("slider_need_help.2")} <span>{t("slider_need_help.3")}</span>{" "}
              {t("slider_need_help.4")}
            </h1>

            <div className="slide-btn-group">
              <a href="tel:19382" className="slide-btn rounded-pill">
                {t("footer_hotline")}
              </a>
            </div>
          </div>
        </div>
      </Slider>
    </Box>
  );
}

export default HomeSlider;
