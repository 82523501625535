import { Box, Grid } from "@mui/material";
import React from "react";
import "./VisionStyle.css";
import visionImg from "../../../imgs/about/vision.png";
import missionImg from "../../../imgs/about/mission.png";
import goalsImg from "../../../imgs/about/goals.png";
import visionLine from "../../../imgs/about/visionLine.png";

import { useTranslation } from "react-i18next";
import { Context } from "../../../components/LocalWrapper";
import { useContext } from "react";

function Vision() {
  const context = useContext(Context);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "90vh",
        padding: "100px 20px 20px 20px",
        // backgroundColor: "var(--section-background)",
        // color: "white",
      }}
      dir={context.dir}
    >
      <Grid container rowSpacing={5}>
        <Grid itm container xs={12} sm={12} md={12} lg={12} spacing={2}>
          {/* ************ VISION UPER LEFT SIDE **************** */}
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className="vision-img-container"
            >
              <img src={visionImg} alt="ETIT" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              className="vision-det-container"
            >
              <h1>{t("about_vision_header")}</h1>
              <img src={visionLine} alt="ETIT" />
              <p className="vision_paragraph">{t("about_vision")}</p>
            </Grid>
          </Grid>
          {/* ************ MISSION UPER RIGHT SIDE **************** */}
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className="vision-img-container"
            >
              <img src={missionImg} alt="ETIT" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              className="vision-det-container"
            >
              <h1>{t("about_mission_header")}</h1>
              <img src={visionLine} alt="ETIT" />
              <p className="vision_paragraph">{t("about_mission")}</p>
            </Grid>
          </Grid>
        </Grid>
        {/* ********** GOALS ************ */}
        <Grid item container xs={12} sm={12} md={12} lg={12} className="">
          <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className="vision-img-container"
            >
              <img src={goalsImg} alt="ETIT" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              className="vision-det-container"
            >
              <h1>{t("about_goals_header")}</h1>
              <img src={visionLine} alt="ETIT" />
              <p className="vision_paragraph">{t("about_goals")}</p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Vision;
