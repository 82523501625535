import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import ReservationStepper from "./ReservationStepper";
import { Context } from "../LocalWrapper";
import { t } from "i18next";
import "./reservationStyle.css";

const Reservation = () => {
  const context = useContext(Context);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        padding: "7% 3%",
      }}
      // dir={context.dir}
    >
      <h2 className="book-header">{t("book_header")}</h2>
      <h3 className="book-p">{t("book_p")}</h3>
      <ReservationStepper />
    </Box>
  );
};

export default Reservation;
