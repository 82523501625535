import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Slide,
  Switch,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import "./ServicesStyle.css";
import fleetServImg from "../../imgs/services/fleetServ.jpg";
import assetsTracking from "../../imgs/services/assets.jpg";
import personalTracking from "../../imgs/services/perTrack.jpg";
import vehicleTrackingImg from "../../imgs/services/vehicleTracking.jpg";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Context } from "../LocalWrapper";

import { handleScrollFun } from "../../util/handleScrollFun";

function Services() {
  const context = useContext(Context);
  const { t } = useTranslation();

  const elementRef1 = useRef(null);
  const elementRef2 = useRef(null);
  const elementRef3 = useRef(null);
  const elementRef4 = useRef(null);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);

  useEffect(() => {
    if (elementRef1.current) {
      handleScrollFun(elementRef1, setIsVisible1);
    }
    if (elementRef2.current) {
      handleScrollFun(elementRef2, setIsVisible2);
    }
    if (elementRef3.current) {
      handleScrollFun(elementRef3, setIsVisible3);
    }
    if (elementRef4.current) {
      handleScrollFun(elementRef4, setIsVisible4);
    }
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        // p: 3,
        backgroundColor: "#F3F3F3",
      }}
      dir={context.dir}
    >
      <Box sx={{ flexGrow: 1, color: "black" }}>
        <h1 className="service-header">{t("serv_header")}</h1>

        {/****************** fleet management system */}
        <Grid sx={{ p: "20px 5px" }} container ref={elementRef3}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-container"
          >
            <Slide
              direction="left"
              timeout={1000}
              in={isVisible3}
              container={elementRef3.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img
                src={fleetServImg}
                alt="tracking"
                className="fleet-serv-img"
              />
            </Slide>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Slide
              direction="up"
              timeout={1000}
              in={isVisible3}
              container={elementRef3.current}
              // mountOnEnter
              // unmountOnExit
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10% 0px",
                }}
              >
                {context.dir === "ltr" ? (
                  <h1 className="serv-subtitle" variant="h2">
                    <span>{t("serv_fleet_t_bold")}</span>{" "}
                    {t("serv_fleet_t_normal")}
                  </h1>
                ) : (
                  <h1 className="serv-subtitle" variant="h2">
                    {t("serv_fleet_t_normal")}{" "}
                    <span>{t("serv_fleet_t_bold")}</span>
                  </h1>
                )}
                <p className="serv-paragraph">{t("serv_fleet_p")}</p>
                <HashLink to="/tracking-services-det#fleet" key="fleet" smooth>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#FF8C05",

                      fontSize: "20px",
                      fontWeight: "600",
                      padding: "10px",
                      margin: "10px",
                      "&:hover": {
                        backgroundColor: "#d35400",
                      },
                    }}
                    // onClick={(e) => alert("helow")}
                  >
                    {t("read_more")}
                  </Button>
                </HashLink>
              </Box>
            </Slide>
          </Grid>
        </Grid>

        {/******************* assets tracking */}
        <Grid
          sx={{ p: "20px 5px", backgroundColor: "#2c3e50" }}
          container
          ref={elementRef4}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-container"
            sx={{ display: { xs: "flex", sm: "none", md: "none", lg: "none" } }}
          >
            <Slide
              direction="left"
              timeout={1000}
              in={isVisible4}
              container={elementRef4.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img
                src={assetsTracking}
                alt="tracking"
                className="fleet-serv-img"
              />
            </Slide>
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Slide
              direction="up"
              timeout={1000}
              in={isVisible4}
              container={elementRef4.current}
              // mountOnEnter
              // unmountOnExit
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10% 0px",
                }}
              >
                {context.dir === "ltr" ? (
                  <h1 className="serv-subtitle-light" variant="h2">
                    <span>{t("serv_assets_t_bold")}</span>
                    {t("serv_assets_t_normal")}
                  </h1>
                ) : (
                  <h1 className="serv-subtitle-light" variant="h2">
                    {t("serv_assets_t_normal")}{" "}
                    <span>{t("serv_assets_t_bold")}</span>
                  </h1>
                )}
                <p className="serv-paragraph-light">{t("serv_assets_p")}</p>
                <HashLink
                  to="/tracking-services-det#assets"
                  key="assets"
                  smooth
                >
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#FF8C05",
                      fontSize: "20px",
                      fontWeight: "600",
                      padding: "10px",
                      margin: "10px",
                      "&:hover": {
                        backgroundColor: "#d35400",
                      },
                    }}
                  >
                    {t("read_more")}
                  </Button>
                </HashLink>
              </Box>
            </Slide>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-container"
            sx={{ display: { xs: "none", sm: "flex", md: "flex", lg: "flex" } }}
          >
            <Slide
              direction="right"
              timeout={1000}
              in={isVisible4}
              container={elementRef4.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img
                src={assetsTracking}
                alt="tracking"
                className="fleet-serv-img"
              />
            </Slide>
          </Grid>
        </Grid>

        {/****************** personal tracking */}
        <Grid sx={{ p: "10px 5px" }} container ref={elementRef1}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-container"
          >
            <Slide
              direction="left"
              timeout={1000}
              in={isVisible1}
              container={elementRef1.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img
                src={personalTracking}
                alt="tracking"
                className="fleet-serv-img"
              />
            </Slide>
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Slide
              direction="up"
              timeout={1000}
              in={isVisible1}
              container={elementRef1.current}
              // mountOnEnter
              // unmountOnExit
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10% 0px",
                }}
              >
                {context.dir === "ltr" ? (
                  <h1 className="serv-subtitle" variant="h2">
                    <span>{t("serv_personal_t_bold")}</span>{" "}
                    {t("serv_personal_t_normal")}
                  </h1>
                ) : (
                  <h1 className="serv-subtitle" variant="h2">
                    {t("serv_personal_t_normal")}
                    <span>{t("serv_personal_t_bold")}</span>{" "}
                  </h1>
                )}
                <p className="serv-paragraph">{t("serv_personal_p")}</p>
                <HashLink
                  to="/tracking-services-det#personal"
                  key="personal"
                  smooth
                >
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#FF8C05",
                      fontSize: "20px",
                      fontWeight: "600",
                      padding: "10px",
                      margin: "10px",
                      "&:hover": {
                        backgroundColor: "#d35400",
                      },
                    }}
                  >
                    {t("read_more")}
                  </Button>
                </HashLink>
              </Box>
            </Slide>
          </Grid>
        </Grid>

        {/******************* vehicale tracking */}
        <Grid
          sx={{ p: "20px 5px", backgroundColor: "#2c3e50" }}
          container
          ref={elementRef2}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-container"
            sx={{ display: { xs: "flex", sm: "none", md: "none", lg: "none" } }}
          >
            <Slide
              direction="left"
              timeout={1000}
              in={isVisible2}
              container={elementRef2.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img
                src={vehicleTrackingImg}
                alt="tracking"
                className="fleet-serv-img"
              />
            </Slide>
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Slide
              direction="up"
              timeout={1000}
              in={isVisible2}
              container={elementRef2.current}
              // mountOnEnter
              // unmountOnExit
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10% 0px",
                }}
              >
                {context.dir === "ltr" ? (
                  <h1 className="serv-subtitle-light" variant="h2">
                    <span>{t("serv_vehicle_t_bold")}</span>
                    {t("serv_vehicle_t_normal")}
                  </h1>
                ) : (
                  <h1 className="serv-subtitle-light" variant="h2">
                    {t("serv_vehicle_t_normal")}{" "}
                    <span>{t("serv_vehicle_t_bold")}</span>
                  </h1>
                )}
                <p className="serv-paragraph-light">{t("serv_vehicle_p")}</p>
                <HashLink
                  to="/tracking-services-det#vehicle"
                  key="vehicle"
                  smooth
                >
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#FF8C05",
                      fontSize: "20px",
                      fontWeight: "600",
                      padding: "10px",
                      margin: "10px",
                      "&:hover": {
                        backgroundColor: "#d35400",
                      },
                    }}
                  >
                    {t("read_more")}
                  </Button>
                </HashLink>
              </Box>
            </Slide>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="serv-img-container"
            sx={{ display: { xs: "none", sm: "flex", md: "flex", lg: "flex" } }}
          >
            <Slide
              direction="right"
              timeout={1000}
              in={isVisible2}
              container={elementRef2.current}
              // mountOnEnter
              // unmountOnExit
            >
              <img
                src={vehicleTrackingImg}
                alt="tracking"
                className="fleet-serv-img"
              />
            </Slide>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Services;
