import { Box, Grid } from "@mui/material";
import React from "react";

import "./CeoStyle.css";
import ceoImg from "../../../imgs/ceo.jpg";
import formerCeoImg from "../../../imgs/former_ceo.png";

import { Context } from "../../LocalWrapper";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function Ceo() {
  const context = useContext(Context);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "10vh",
        p: 3,
        pt: 8,
        backgroundColor: "var(--light)",
      }}
    >
      <Box sx={{ flexGrow: 1, justifyContent: "start" }}>
        {/****************** ceo message */}

        <h1 className="chairman-subtitle" id="ceo1">
          <span>{t("about_current_ceo_bold")}</span>
          {/* {t("about_current_ceo_normal")} */}
        </h1>

        <Grid
          sx={{ p: "40px 20px", mb: "30px", backgroundColor: "var(--dark)" }}
          container
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="chairman-img-container"
          >
            <img src={ceoImg} alt="ceo" className="chairman-img" />
            <div className="chairman-name" id="ceo1">
              <h2> {t("about_current_ceo_name")} </h2>
              <h4> {t("about_current_ceo_title")} </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box className="chairman-word-container" dir={context.dir}>
              <p className="chairman-paragraph">{t("about_current_ceo_p.0")}</p>
              <p className="chairman-paragraph">{t("about_current_ceo_p.1")}</p>
              <p className="chairman-paragraph">{t("about_current_ceo_p.2")}</p>
              <p className="chairman-paragraph">{t("about_current_ceo_p.3")}</p>
            </Box>
          </Grid>
        </Grid>
        <h1 className="chairman-subtitle" id="ceo1">
          <span>{t("about_former_ceo_bold")}</span>
        </h1>
        <Grid sx={{ p: "40px 20px", backgroundColor: "var(--dark)" }} container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="chairman-img-container"
          >
            <img src={formerCeoImg} alt="ceo" className="chairman-img" />
            <div className="chairman-name" id="ceo1">
              <h2> {t("about_former_ceo_name")} </h2>
              <h4> {t("about_former_ceo_title")} </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box className="chairman-word-container" dir={context.dir}>
              <p className="chairman-paragraph">{t("about_former_ceo_p.0")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.1")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.2")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.3")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.4")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.5")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.6")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.7")}</p>
              <p className="chairman-paragraph">{t("about_former_ceo_p.8")}</p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Ceo;
