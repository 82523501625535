import React from "react";
import { Grid } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";

import playImg from "../../imgs/apps/googlePlay.png";
import appStoreImg from "../../imgs/apps/appStore.png";
import { useTranslation } from "react-i18next";

function DownloadApps() {
  const { t } = useTranslation();

  return (
    <div>
      <Grid item container className="download-section">
        {/* <DownloadPage /> */}
        <Grid item>
          <div className="qr-box">
            <QRCodeSVG
              value="https://play.google.com/store/apps/details?id=com.etit"
              size={120}
            />
            <p>{t("fms_page_google_play")}</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.etit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playImg} alt="Google Play" className="store-badge" />
            </a>
          </div>
        </Grid>
        <Grid item className="download-section">
          <div className="qr-box">
            <QRCodeSVG
              value="https://apps.apple.com/eg/app/etit-fms/id1187884719?platform=iphone"
              size={120}
            />
            <p>{t("fms_page_app_store")}</p>
            <a
              href="https://apps.apple.com/eg/app/etit-fms/id1187884719?platform=iphone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStoreImg} alt="App Store" className="store-badge" />
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DownloadApps;
