import { Box } from "@mui/material";
import React from "react";
import CompProfile from "./compProfile/CompProfile";
import CompDirectors from "./compDirectors/CompDirectors";
import Vision from "./vision/Vision";
import PrivacyPolicy from "./privacyPolicy/PrivacyPolicy";
import BestEmp from "./bestEmp/BestEmp";
import Ceo from "./ceo/Ceo";
import InfoVideo from "./infoVideo/InfoVideo";

function About() {
  return (
    <Box
      component="main"
      sx={{
        width: "100%",
        minHeight: "100vh",
        p: 0,
      }}
    >
      <CompProfile />
      <InfoVideo />
      {/* <BusinessCard /> */}
      <Ceo />
      <Vision />
      <BestEmp />
      {/* <CompDirectors /> */}
      <PrivacyPolicy />
    </Box>
  );
}

export default About;
