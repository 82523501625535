import React, { useContext } from "react";
import "./TopbarStyle.css";
import { Context } from "../../LocalWrapper";
import t from "i18next";
import { useTranslation } from "react-i18next";

export default function Topbar() {
  const context = useContext(Context);
  const { t } = useTranslation();
  return (
    <div
      dir="ltr"
      className="container-fluid bg-secondary ps-5 pe-0 d-none d-lg-block"
      style={{ width: "100vw" }}
    >
      <div className="row gx-0">
        <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
          <div className="d-inline-flex align-items-center">
            <a
              className="left-links py-2 px-3 border-end"
              href="https://www.iubenda.com/privacy-policy/95821041"
            >
              <small style={{ textTransform: "capitalize" }}>
                {t("about_privacy_policy_header")}
              </small>
            </a>
            <a className="left-links py-2 px-3 border-end" href="/documents">
              <small>{t("nav_docs")}</small>
            </a>
            <a className="left-links py-2 px-3 border-end" href="/centers">
              <small>{t("nav_centers")}</small>
            </a>
          </div>
        </div>
        <div className="col-md-6 text-center text-lg-end">
          <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
            <div className="me-3 pe-3 border-end py-2">
              <p className="m-0">
                <a href=" mailto:info@etit-eg.com" className="contact">
                  info@etit-eg.com
                </a>
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <a href="tel:19382" className="contact">
                  19382
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
